import { Status } from '@shared/enums/Status';
import { TProductUse } from './TProductUse';
export function buildCenterMock(overwrites: Partial<TCenter>): TCenter {
  const defaultCenter: TCenter = {
    c_340b: false,
    c_address: ``,
    c_beds: 0,
    c_beds_ICU: 0,
    c_city: ``,
    c_comment: ``,
    c_country: ``,
    c_email: ``,
    website: ``,
    c_for_profit: false,
    c_gene_therapy_center: false,
    c_id: ``,
    c_name: ``,
    c_original_name: ``,
    c_state: ``,
    c_sales_data_filename: ``,
    c_aliases: [],
    articleId: ``,
    authorId: ``,
    affiliationYear: 0,
    status: Status.OK,
    c_zip: '',
    c_US_AHA_ID: '',
    c_US_GPO_name: '',
    c_US_GPO_city: '',
    c_US_GPO_state: '',
    c_US_directpurchase: false,
    c_US_systemID: '',
    c_US_system_name: '',
    c_US_system_city: '',
    c_US_system_state: '',
    c_inpatient_days: 0,
    c_inpatient_surgeries: 0,
    c_adultcardiacsurgery: false,
    c_trauma_center: '',
    c_US_medicaremedicaid: 0,
    c_proj_CABP_surgeries: 0,
    c_proj_periop_hemorrhage: 0,
    c_country_systemID2: '0',
    c_country_systemID3: '0',
    projects: [],
    projectsData: [],
    custom_metrics: []
  };
  return Object.assign(defaultCenter, overwrites);
}
export function buildCenterProjectDataMock(
  overwrites: Partial<TCenterProjectsData>
): TCenterProjectsData {
  const defaultCenterProjectsData: TCenterProjectsData = {
    projectId: ``,
    valueScore: 0,
    impactScore: 0,
    clinicalTrialsScore: 0,
    potentialScore: 0,
    utilScore: 0,
    c_project_region1: ``,
    c_project_region2: ``,
    c_project_reference: false,
    c_proj_impact_tier: '',
    c_proj_bu1_potential1: ``,
    c_proj_bu1_potential2: ``,
    c_proj_patient_potential1: 0,
    c_proj_patient_potential2: 0,
    c_proj_patient_potential3: 0,
    c_proj_patient_potential4: 0,
    c_proj_patient_potential5: 0,
    patient_utilisation1: 0,
    sales_latest_period: 0,
    sales_patient_equivalents: 0,
    sales_momentum_short: 0,
    sales_momentum_long: 0,
    c_proj_bu1_patient_group1: false,
    c_proj_bu1_patient_group2: false,
    c_proj_bu1_patient_group3: false,
    c_proj_bu1_use1: ``,
    c_proj_bu1_use2: ``,
    c_proj_bu1_use3: ``,
    c_proj_bu1_clin1: ``,
    c_proj_bu1_clin2: ``,
    c_proj_procedures: ``,
    c_proj_treatment_center: false,
    c_proj_clinical_trials: [],
    c_center_alias: [],
    c_proj_publications: [],
    c_decile: 0,
    products_use: [],
    center_vision: '',
    center_growth_driver: '',
    center_growth_obstacle: '',
    center_objectives: '',
    treatment_goals_enabled: [],
    treatment_option_total_count: 0,
    treatment_options_validated: [],
    treatment_options_counts: [],
    treatment_uses_enabled: [],
    center_decision_makers: '',
    centerNews: ''
  };
  return Object.assign(defaultCenterProjectsData, overwrites);
}
export type CommentType = `notEnoughData` | `invalidCountry` | ``;

export interface TCustomMetric {
  name: string;
  year: string;
  value: number;
}

export interface TOctapharmaCenter {
  c_gene_therapy_center: boolean;
  c_aliases: string[];
}

export interface TCenter extends TOctapharmaCenter {
  c_id: string;
  c_name: string;
  c_original_name: string;
  c_city: string;
  c_state: string;
  c_country: string;
  c_beds: number;
  c_beds_ICU: number;
  c_for_profit: boolean;
  c_340b: boolean;
  c_comment: string;
  c_email: string;
  website: string;
  c_address: string;
  c_sales_data_filename: string;
  articleId: string;
  authorId: string;
  status: Status;
  affiliationYear: number;
  c_zip: string;
  c_US_AHA_ID: string;
  c_US_GPO_name: string;
  c_US_GPO_city: string;
  c_US_GPO_state: string;
  c_US_directpurchase: boolean;
  c_US_systemID: string;
  c_US_system_name: string;
  c_US_system_city: string;
  c_US_system_state: string;
  c_inpatient_days: number;
  c_inpatient_surgeries: number;
  c_adultcardiacsurgery: boolean;
  c_trauma_center: string;
  c_US_medicaremedicaid: number;
  c_proj_CABP_surgeries: number;
  c_proj_periop_hemorrhage: number;
  c_country_systemID2: string;
  c_country_systemID3: string;

  projects: string[]; //TODO: could be removed because we have same information in projectsData
  projectsData: TCenterProjectsData[];

  custom_metrics: TCustomMetric[];
}

export interface TCenterExtended extends TCenter {
  number_of_publications: number;
  number_of_clinical_trials: number;
}

export type TCenterProjectsDataProp = string | number | boolean | string[];

export interface TCenterProjectsData {
  projectId: string;
  valueScore: number;
  impactScore: number;
  potentialScore: number;
  utilScore: number;
  clinicalTrialsScore: number;
  c_project_region1: string;
  c_project_region2: string;
  c_project_reference: boolean;
  c_proj_impact_tier: string;
  c_proj_bu1_potential1: string;
  c_proj_bu1_potential2: string;
  c_proj_patient_potential1: number;
  c_proj_patient_potential2: number;
  c_proj_patient_potential3: number;
  c_proj_patient_potential4: number;
  c_proj_patient_potential5: number;
  patient_utilisation1: number;
  sales_latest_period: number;
  sales_patient_equivalents: number;
  sales_momentum_short: number;
  sales_momentum_long: number;
  c_proj_bu1_patient_group1: boolean;
  c_proj_bu1_patient_group2: boolean;
  c_proj_bu1_patient_group3: boolean;
  c_proj_bu1_use1: string;
  c_proj_bu1_use2: string;
  c_proj_bu1_use3: string;
  c_proj_bu1_clin1: string;
  c_proj_bu1_clin2: string;
  c_proj_procedures: string;
  c_proj_treatment_center: boolean;
  c_proj_clinical_trials: string[];
  c_center_alias: string[];
  c_proj_publications: string[];
  c_decile: number;
  products_use: TProductUse[];

  center_vision: string;
  center_growth_driver: string;
  center_growth_obstacle: string;
  center_objectives: string;

  treatment_goals_enabled: boolean[];
  treatment_option_total_count: number;
  treatment_options_validated: boolean[];
  treatment_options_counts: number[];
  treatment_uses_enabled: boolean[];

  center_decision_makers: string;
  centerNews: string;
}
