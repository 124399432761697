import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@shared/Endpoints';
import { SearchArea } from '@shared/enums/SearchArea';
import {
  TBatchUpdateParams,
  TCenterBatchUpdateResults,
} from '@shared/interfaces/TBatchUpdateParams';
import {
  TCenter,
  TCenterProjectsData,
  buildCenterProjectDataMock,
} from '@shared/interfaces/TCenter';
import { TCenterQueryParams } from '@shared/interfaces/TCenterQueryParams';
import { TCenterUpdateParams } from '@shared/interfaces/TCenterUpdateParams';
import { TImportMappingItem } from '@shared/interfaces/TImportMappingItem';
import { TCenterMergeRowsParams } from '@shared/interfaces/TMergeRowsParams';
import { TReportQuery } from '@shared/interfaces/TReportQuery';
import {
  TPaging,
  TReportCentersResults,
  TSearchParams,
} from '@shared/interfaces/TSearchParams';
import { TUpdateCenter } from '@shared/interfaces/TUpdateCenter';
import { UserLevel } from '@shared/interfaces/TUser';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import saveAs from 'file-saver';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CSVUtils } from 'src/utils/csvUtils';
import { CategoriesService } from './CategoriesService';
import { LoadingService, TLoadingItem } from './LoadingService';
import { RegionsService, WORLD_REGION } from './RegionsService';
import { RiseAppApiService } from './RiseAppApiService';
import { SSEService } from './SSEService';
import { AuthService, TCurrentUser } from './auth/auth.service';
import { PagingService } from './paging/PagingService';
import { TPagingItem } from './paging/TPagingItem';

@Injectable()
export class CentersService {
  private _centers: BehaviorSubject<TCenter[]> = new BehaviorSubject<TCenter[]>(
    []
  );
  private _extendedCentersCount: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  constructor(
    private _loadingService: LoadingService,
    private _http: HttpClient,
    private _pagingService: PagingService,
    private _categoriesService: CategoriesService,
    private _riseApi: RiseAppApiService,
    private _SSEService: SSEService,
    private _regionsService: RegionsService,
    private _authService: AuthService
  ) {
    this._riseApi.setLogCurrentCenters(() => {
      return this._centers.value;
    });
  }

  public getProjectData(
    center: TCenter,
    projectId: string
  ): TCenterProjectsData {
    const newProjectData: TCenterProjectsData = buildCenterProjectDataMock({
      projectId: projectId,
    });
    const projectData = center.projectsData.find(
      (p) => p.projectId === projectId
    );
    if (isNullOrUndefined(projectData)) {
      center.projectsData.push(newProjectData);
      return newProjectData;
    }
    return projectData;
  }

  public mergeRows(params: TCenterMergeRowsParams, callback: () => void): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Merging centers`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'mergeCenters',
      params: ``,
    });
    const response = this._http.post<TCenter>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        this._loadingService.triggerOkMessage(
          `Center - ${response.c_name} merged`
        );
        callback();
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public setCentersFromReport(
    params: TReportQuery,
    callback: (paging: TPaging) => void
  ): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching Centers`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL(`report`, {
      base: environment.apiUrl,
      endpoint: 'centers',
      params: ``,
    });
    const response = this._http.post<TReportCentersResults>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        const centers: TCenter[] = response.centers.map((result) => {
          return {
            ...result,
          };
        });
        this._centers.next(centers);
        callback(response.paging);
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public getCentersCountObservable(): Observable<number> {
    return this._extendedCentersCount.asObservable();
  }

  public getCentersCount(): number {
    return this._extendedCentersCount.value;
  }

  public setCentersCount(articlesCount: number): void {
    this._extendedCentersCount.next(articlesCount);
  }

  public getCentersObservable(): Observable<TCenter[]> {
    return this._centers.asObservable();
  }

  public getCenters(): TCenter[] {
    return this._centers.value;
  }

  public resetCenters(): void {
    this._extendedCentersCount.next(0);
    this._centers.next([]);
  }

  public getCentersByName(centerName: string): Observable<TCenter[]> {
    //TODO add params builder to keep in sync with interface
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getCentersByName',
      params: `?data=${centerName}`,
    });
    return this._http.get<TCenter[]>(url).pipe(tap((data) => data));
  }

  public getCenterByName(
    centerName: string,
    projectId: string
  ): Observable<TCenter[]> {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getCenterByName',
      params: `?data=${centerName}&projectId=${projectId}`,
    });
    return this._http.get<TCenter[]>(url).pipe(tap((data) => data));
  }

  public getCentersById(id: string): Observable<TCenter[]> {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getCentersById',
      params: `?data=${id}`,
    });
    return this._http.get<TCenter[]>(url).pipe(tap((data) => data));
  }

  public getAllCenters(): Observable<TCenter[]> {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getCenters',
      params: ``,
    });
    return this._http.get<TCenter[]>(url).pipe(tap((data) => data));
  }

  public batchUpdate(data: TUpdateCenter[]): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Updating Centers`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'centerBatchUpdate',
      params: ``,
    });
    const response = this._http.post<{ data: TUpdateCenter[] }>(url, { data });
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: () => {
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public batchUpdateWithFilters(
    data: TBatchUpdateParams
  ): Observable<TCenterBatchUpdateResults> {
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'batchUpdateCenters',
      params: ``,
    });
    return this._http
      .post<TCenterBatchUpdateResults>(url, data)
      .pipe(tap((data) => data));
  }

  public searchAndSetCenters(
    params: TSearchParams<TCenter>,
    callback?: (response: TSearchParams<TCenter>) => void
  ): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching Centers`);
    this._loadingService.addLoadingItem(loadingItem);

    const regions: string[] = this._regionsService
      .getSelectedCountries()
      .map((r) => r.value);
    const { showAllCountries, countries } = this.buildCountries(regions);

    const projectRegions = this.buildProjectRegions(params.projectId);
    params.projectRegions = projectRegions;
    params.countries = countries;
    params.showAllCountries = showAllCountries;

    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'searchCenters',
      params: ``,
    });
    const response = this._http.post<TSearchParams<TCenter>>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        const currentPaging: TPagingItem = this._pagingService.getPagingItem(
          params.projectId
        );
        currentPaging.centersPaging = response.paging;
        this._pagingService.updatePagingItem(params.projectId, currentPaging);
        this._centers.next(response.results);
        if (callback !== undefined) {
          callback(response);
        }
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public setExtendedCenters(
    ids: string[],
    categoryId: string,
    projectId: string
  ): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching centers`);
    this._loadingService.addLoadingItem(loadingItem);
    const idsParam: string = `&ids=${ids.join(`,`)}`;
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getCenters',
      params: `?categoryId=${categoryId}${idsParam}&projectId=${projectId}`,
    });
    this._http.get<TCenter[]>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (results) => {
        this._centers.next(results);
        this._loadingService.removeItem(loadingItem);
        if (ids.length !== results.length) {
          const resultsIds: string[] = results.map((center) => center.c_id);
          console.warn(
            `Returned data missing ${ids
              .filter((id) => !resultsIds.includes(id))
              .join(`, `)}`
          );
        }
      },
    });
  }

  public deleteCenter(params: TCenterQueryParams, callback?: () => void): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching centers`);
    this._loadingService.addLoadingItem(loadingItem);

    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'deleteCenters',
      params: `?ids=${params.ids}&projectId=${params.projectId}`,
    });
    this._http.get<{ results: string }>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: () => {
        if (callback !== undefined) {
          callback();
        }
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public async importCenters(
    e: Event,
    callback: () => void,
    projectId: string,
    mappings: TImportMappingItem[]
  ): Promise<void> {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Importing centers`);
    this._loadingService.addLoadingItem(loadingItem);
    const files: FileList | null = target.files;
    const url: string = Endpoints.BUILD_URL('import', {
      base: environment.apiUrl,
      endpoint: 'centers',
      params: ``,
    });
    const formData = await CSVUtils.getImportFormData(
      files![0],
      projectId,
      mappings
    );
    const response = this._http.post<string[]>(url, formData);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        callback();
        this._categoriesService.setCategories();
        this._loadingService.removeItem(loadingItem);
        this._loadingService.triggerOkMessage(`Importing centers finished`);
      },
    });
  }

  public async importCentersProductsSales(
    e: Event,
    callback: () => void,
    projectId: string,
    mappings: TImportMappingItem[]
  ): Promise<void> {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      `Importing product sales`
    );
    this._loadingService.addLoadingItem(loadingItem);
    const files: FileList | null = target.files;
    const url: string = Endpoints.BUILD_URL('import', {
      base: environment.apiUrl,
      endpoint: 'centersProductsSales',
      params: ``,
    });
    const formData = await CSVUtils.getImportFormData(
      files![0],
      projectId,
      mappings
    );
    const response = this._http.post<string[]>(url, formData);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        callback();
        this._categoriesService.setCategories();
        this._loadingService.removeItem(loadingItem);
        this._loadingService.triggerOkMessage(
          `Importing product sales finished`
        );
      },
    });
  }

  public downloadCentersFromReport(params: TReportQuery): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Downloading Centers`);
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService._handleGenerateExcelSSE(() => {
      this._loadingService.removeItem(loadingItem);
    });
    const url: string = Endpoints.BUILD_URL(`export`, {
      base: environment.apiUrl,
      endpoint: `centersReport`,
      params: ``,
    });
    this._http.post(url, params, { responseType: 'arraybuffer' }).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (results) => {
        const projectName: string =
          this._categoriesService.getProject(params.projectId)?.name ??
          params.projectId;
        const blob = new Blob([results], { type: 'application/octet-stream' });
        const fileName = `${projectName}-Centers-report.csv`;
        this._loadingService.removeItem(loadingItem);
        saveAs(blob, fileName);
      },
    });
  }

  public downloadCenters(categoryId: string, projectId: string): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Downloading Centers`);
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService._handleGenerateExcelSSE(() => {
      this._loadingService.removeItem(loadingItem);
    });
    const url: string = Endpoints.BUILD_URL(`export`, {
      base: environment.apiUrl,
      endpoint: `centers`,
      params: `?categoryId=${categoryId}&projectId=${projectId}`,
    });
    this._http.get(url, { responseType: 'arraybuffer' }).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (results) => {
        const projectName: string =
          this._categoriesService.getProject(projectId)?.name ?? projectId;
        const blob = new Blob([results], { type: 'application/octet-stream' });
        const fileName = `${projectName}-centers.csv`;
        this._loadingService.removeItem(loadingItem);
        saveAs(blob, fileName);
      },
    });
  }

  public migrateCenters(): void {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: `migrateCenters`,
      params: ``,
    });
    this._http.get<{ status: string }>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        console.log(response);
      },
    });
  }

  public updateCenter(
    center: TCenter,
    projectId: string,
    callback?: () => void
  ): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Updating center`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('score', {
      base: environment.apiUrl,
      endpoint: 'updateSingleCenterWithScore',
      params: ``,
    });
    const payload: TCenterUpdateParams = {
      projectId: projectId,
      update: center,
    };
    const response = this._http.post<{ centerId: string }>(url, payload);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        this._loadingService.removeItem(loadingItem);
        this.setExtendedCenters([res.centerId], ``, projectId);
        if (callback !== undefined) {
          callback();
        }
      },
    });
  }

  public getCTForCenter(id: string, projectId: string): Observable<string[]> {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getCTsForCenter',
      params: `?id=${id}&projectId=${projectId}`,
    });
    return this._http.get<string[]>(url).pipe(tap((data) => data));
  }

  public handleNextCenter(
    centerId: string,
    projectId: string,
    callback: (centerId: string) => void
  ): void {
    const projectRegions = this.buildProjectRegions(projectId);

    const currentPaging: TPagingItem =
      this._pagingService.getPagingItem(projectId);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'searchCenters',
      params: ``,
    });
    const regions: string[] = this._regionsService
      .getSelectedCountries()
      .map((r) => r.value);

    const { showAllCountries, countries } = this.buildCountries(regions);
    const params: TSearchParams<TCenter> = {
      area: SearchArea.Centers,
      paging: currentPaging.centersPaging,
      projectId,
      query: currentPaging.centersPaging.query,
      results: [],
      countries,
      showAllCountries,
      select: { c_id: 1 },
      projectRegions,
    };

    const response = this._http.post<TSearchParams<TCenter>>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        const currentCenterIndex: number = res.results.findIndex(
          (it) => it.c_id === centerId
        );
        const nextCenter: TCenter | undefined =
          res.results[currentCenterIndex + 1];
        if (isNullOrUndefined(nextCenter)) {
          const currentCenterTotalIndex =
            params.paging.pageIndex * params.paging.pageSize +
            currentCenterIndex +
            1;
          if (currentCenterTotalIndex >= params.paging.total) {
            this._loadingService.triggerInfoMessage(`No more Centers`, 1000);
          } else {
            params.paging.pageIndex += 1;
            this.searchAndSetCenters(params, (newRes) => {
              const currentCenterIndex: number = newRes.results.findIndex(
                (it) => it.c_id === centerId
              );
              const newPageNextCenter: TCenter | undefined =
                newRes.results[currentCenterIndex + 1];
              if (isNullOrUndefined(newPageNextCenter)) {
                this._loadingService.triggerInfoMessage(
                  `Center not found`,
                  1000
                );
              } else {
                callback(newPageNextCenter.c_id);
              }
            });
          }
        } else {
          callback(nextCenter.c_id);
        }
      },
    });
  }

  public handlePreviousCenter(
    centerId: string,
    projectId: string,
    callback: (centerId: string) => void
  ): void {
    const projectRegions = this.buildProjectRegions(projectId);

    const currentPaging: TPagingItem =
      this._pagingService.getPagingItem(projectId);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'searchCenters',
      params: ``,
    });
    const regions: string[] = this._regionsService
      .getSelectedCountries()
      .map((r) => r.value);
    const { showAllCountries, countries } = this.buildCountries(regions);
    const params: TSearchParams<TCenter> = {
      area: SearchArea.Centers,
      paging: currentPaging.centersPaging,
      projectId,
      query: currentPaging.centersPaging.query,
      results: [],
      countries,
      showAllCountries,
      select: { c_id: 1 },
      projectRegions,
    };

    const response = this._http.post<TSearchParams<TCenter>>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        const currentCenterIndex: number = res.results.findIndex(
          (it) => it.c_id === centerId
        );
        const prevCenter: TCenter | undefined =
          res.results[currentCenterIndex - 1];
        if (isNullOrUndefined(prevCenter)) {
          const currentCenterTotalIndex =
            params.paging.pageIndex * params.paging.pageSize +
            currentCenterIndex -
            1;
          if (currentCenterTotalIndex < 0) {
            this._loadingService.triggerInfoMessage(`No more Centers`, 1000);
          } else {
            params.paging.pageIndex -= 1;
            this.searchAndSetCenters(params, (newRes) => {
              const newPageNextCenter: TCenter | undefined =
                newRes.results[newRes.results.length - 1];
              if (isNullOrUndefined(newPageNextCenter)) {
                this._loadingService.triggerInfoMessage(
                  `Center not found`,
                  1000
                );
              } else {
                callback(newPageNextCenter.c_id);
              }
            });
          }
        } else {
          callback(prevCenter.c_id);
        }
      },
    });
  }

  private buildCountries(regions: string[]): {
    showAllCountries: boolean;
    countries: string[];
  } {
    const currentUser: TCurrentUser = this._authService.getCurrentUser();
    const isAdmin = currentUser?.level === UserLevel.Admin;
    const showAllCountries =
      isAdmin || (currentUser?.countries || []).length === 0;
    return {
      showAllCountries: showAllCountries,
      countries: regions.includes(WORLD_REGION.value) ? [] : regions,
    };
  }

  private buildProjectRegions(projectId: string) {
    let projectRegions = {
      filter: false,
      filterBy: [] as string[],
    };
    const currentUser: TCurrentUser = this._authService.getCurrentUser();
    const userRegions =
      currentUser?.projectsRegions
        ?.find((pr) => pr.projectId === projectId)
        ?.regions.map((reg) => reg.id) ?? [];

    projectRegions = {
      filter: currentUser?.level !== UserLevel.Admin && userRegions.length > 0,
      filterBy: userRegions,
    };

    return projectRegions;
  }
}
