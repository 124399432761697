import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs';

import { SearchArea } from '@shared/enums/SearchArea';
import { TSearchGroup } from '@shared/interfaces/TSearchGroup';
import { TSettings } from '@shared/interfaces/TSettings';

import { AuthService, TCurrentUser } from 'src/app/services/auth/auth.service';
import { ROUTES_PATHS } from '../app-routing.module';
import { CommonService } from '../services/common.service';

import { UserLevel } from '@shared/interfaces/TUser';
import { CategoriesService } from '../services/CategoriesService';
import { RegionsService, TRegion } from '../services/RegionsService';
import { SearchService } from '../services/SearchService';
import { SettingsService } from '../services/SettingsService';
import { getAvatar } from '../system-settings/users-management/user/user.component';
import { handleSelection } from './handleSelection';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelect) public regionSelect: MatSelect;
  public regions: TRegion[] = [];
  public regionsFiltered: TRegion[] = [];
  public selectedRegions = new FormControl<TRegion[]>([]);
  public searchForm = this._formBuilder.group({
    searchGroup: '',
  });
  public searchGroupOptions: Observable<TSearchGroup[]>;
  public searchResults: TSearchGroup[] = [];
  public settings: TSettings | null = null;
  public searchLabel: string = '';
  public currentUser: TCurrentUser | undefined = undefined;
  public userAvatar: string = '';
  private _subs: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _searchService: SearchService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _commonService: CommonService,
    private _regionsService: RegionsService,
    private _categoryService: CategoriesService,
    private _settingsService: SettingsService
  ) {
    this._subs.push(
      this._searchService.getSearchResultsObservable().subscribe((results) => {
        this.searchResults = results;
      })
    );
    this._subs.push(
      this._settingsService.getAppSettingsObservable().subscribe((results) => {
        this.settings = results;
      })
    );
    this.searchGroupOptions = this._searchService.getSearchResultsObservable();
  }

  public onSearchBlur(): void {
    this.searchForm.get('searchGroup')!.setValue(``);
  }

  public ngOnInit(): void {
    this.searchForm
      .get('searchGroup')!
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        if (value && value.length > 2) {
          const projectId: string =
            this._categoryService.getSelectedProject()?.id ?? ``;
          this._searchService.performSearch(value, projectId);
        }
      });

    this._subs.push(
      this._regionsService.getRegionSelectorValues().subscribe((values) => {
        this.regions = values;
        this.regionsFiltered = values;
      })
    );
    this._subs.push(
      this._categoryService
        .getSelectedProjectObservable()
        .subscribe((project) => {
          if (project === undefined) {
            this.searchLabel = `Search (HCP, article, center) - global context`;
          } else {
            this.searchLabel = `Search (HCP, article, center) - project ${project.name} context`;
          }
        })
    );
    this._subs.push(
      this._authService.getCurrentUserObservable().subscribe((user) => {
        this.currentUser = user;
        this.userAvatar = getAvatar(user);
        this.selectedRegions.patchValue(
          this._regionsService.getSelectedCountries()
        );
      })
    );
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public openSystemSettings(): void {
    this._router.navigate([`/${ROUTES_PATHS.systemSettings}`], {
      relativeTo: this._route,
    });
  }

  public handleRegionClick(region: TRegion): void {
    let selectedValues: TRegion[] = this.selectedRegions.getRawValue() ?? [];
    const allowWorld =
      Boolean(this.currentUser?.level === UserLevel.Admin) ||
      Boolean(this.currentUser?.countries?.includes(`World`));

    selectedValues = handleSelection(
      region,
      selectedValues,
      this.settings?.customRegions,
      allowWorld
    );

    this._regionsService.setSelectedCountries(selectedValues);
    this.selectedRegions.patchValue(selectedValues);
    this._categoryService.setCategories();
  }

  public handleSearchClick(group: SearchArea, id: string): void {
    const projectId: string =
      this._categoryService.getSelectedProject()?.id ?? ``;
    const categoryId: string =
      this._categoryService.getCategoryFromProjectId(projectId)?.categoryId ??
      ``;
    this._commonService.navigateToSearchedItem(
      group,
      id,
      categoryId,
      projectId
    );
  }

  public handleHeaderClick(): void {
    this._categoryService.setSelectedProject(``);
    this._router.navigate([`/${ROUTES_PATHS.dashboard}`], {
      relativeTo: this._route,
    });
  }

  public logout(): void {
    this._authService.logout();
  }
}
