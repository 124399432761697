import { Component, Input, OnInit } from '@angular/core';

export interface TDataMaintenanceFooterButtonParams {
  deleteLabel: string;
  deleteCallback: () => void;
  backLabel: string;
  backCallback: () => void;
  saveLabel: string;
  saveCallback: () => void;
  nextLabel: string;
  nextCallback: () => void;
  previousLabel: string;
  previousCallback: () => void;
  addNewLabel: string;
  addNewCallback: () => void;
}

@Component({
  selector: 'app-data-maintenance-footer-button',
  templateUrl: './data-maintenance-footer-button.component.html',
  styleUrls: ['./data-maintenance-footer-button.component.scss'],
})
export class DataMaintenanceFooterButtonComponent implements OnInit {
  @Input() public buttonsData: TDataMaintenanceFooterButtonParams = {
    backCallback: () => console.log(`handle back callback`),
    backLabel: `Back`,
    deleteCallback: () => console.log(`handle delete callback`),
    deleteLabel: `Delete`,
    saveCallback: () => console.log(`handle save callback`),
    saveLabel: `Save`,
    nextCallback: () => console.log(`handle next callback`),
    nextLabel: `Next`,
    previousCallback: () => console.log(`handle previous callback`),
    previousLabel: `Previous`,
    addNewCallback: () => console.log(`handle add new callback`),
    addNewLabel: `Add New`,
  };

  constructor() {
    const observer = new IntersectionObserver(
      ([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1),
      { threshold: [1] }
    );
    const footerEl = document.querySelector(
      'app-data-maintenance-footer-button'
    );
    if (footerEl !== null) {
      observer.observe(footerEl);
    }
  }

  ngOnInit(): void {}
}
