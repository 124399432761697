import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Endpoints } from '@shared/Endpoints';
import { TSalesRegion } from '@shared/interfaces/TProject';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-region-item',
  templateUrl: './region-item.component.html',
  styleUrls: ['./region-item.component.scss'],
})
export class RegionItemComponent {
  public addOnBlur = true;
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(private _http: HttpClient) {}

  @Input()
  public regionData: TSalesRegion = {
    id: ``,
    description: ``,
    name: ``,
    salesData: ``,
  };

  @Input()
  public loadPDF: (filename: string) => void = () => void 0;

  @Input()
  public handleDeleteRegion: ((region: TSalesRegion) => void) | undefined;

  public importSalesData(e: Event): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const files: FileList | null = target.files;
    const url: string = Endpoints.BUILD_URL('import', {
      base: environment.apiUrl,
      endpoint: 'pdf',
      params: ``,
    });

    const formData = new FormData();
    formData.append('file', files![0]);
    const response = this._http.post<{ fileName: string }>(url, formData);
    response.subscribe((res) => {
      this.regionData.salesData = res.fileName;
    });
  }

  public deleteVisible(): boolean {
    return this.handleDeleteRegion !== undefined;
  }

  public deleteRegion(): void {
    if (this.handleDeleteRegion !== undefined) {
      this.handleDeleteRegion(this.regionData);
    }
  }

  public openPDF(): void {
    this.loadPDF(this.regionData.salesData);
  }
}
