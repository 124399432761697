import { Component, Input } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { TSalesRegion } from '@shared/interfaces/TProject';

@Component({
  selector: 'app-project-region-selector',
  templateUrl: './project-region-selector.component.html',
  styleUrls: ['./project-region-selector.component.scss'],
})
export class ProjectRegionSelectorComponent {
  @Input()
  public regions: Record<string, (TSalesRegion & { selected: boolean })[]> = {};
  @Input()
  public projectId: string = '';
  @Input()
  public handleRegionClick: (regs: TSalesRegion[], projectId: string) => void =
    (regs: TSalesRegion[], projectId: string) => {
      //
    };
  public selectedRegions: string[] = [];
  public options: TSalesRegion[] = [];

  constructor() {}

  public ngOnInit(): void {
    this.options = this.regions[this.projectId];
    this.selectedRegions = this.regions[this.projectId]
      .filter((region) => region.selected)
      .map((region) => region.id);
  }

  public handleSelection(event: MatSelectChange): void {
    if ((event.value as string[]).includes('ALL')) {
      this.selectedRegions = [...event.value, ...this.options];
      this.handleRegionClick(this.options, this.projectId);
    } else {
      const selected = this.options.filter((region) =>
        (event.value as string[]).includes(region.id)
      );
      this.handleRegionClick(selected, this.projectId);
    }
  }
}
