import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { SearchArea } from '@shared/enums/SearchArea';
import { TCategory } from '@shared/interfaces/TCategory';
import { THCP } from '@shared/interfaces/THCP';
import { TImportMappingItem } from '@shared/interfaces/TImportMappingItem';
import { TProject } from '@shared/interfaces/TProject';
import { TSearchParams } from '@shared/interfaces/TSearchParams';
import { UserLevel } from '@shared/interfaces/TUser';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { Subscription } from 'rxjs';
import { TDataColumnDef, buildColumnsDef } from 'src/utils/buildColumnsDef';
import { ROUTES_PATHS } from '../app-routing.module';
import { CommonTableComponent } from '../common-table/common-table.component';
import {
  ICommonTableParams,
  IHandleCommonTable,
  SortType,
  THCPTableRow,
  TTDataType,
  TTableRowType,
  TableRowType,
} from '../common-table/common-table.component.types';
import { TableDataExtender } from '../common-table/utils/TableDataExtender';
import { NEW_HCP_ID } from '../hcp/hcp.component';
import { TComponentType, THCPParams } from '../interfaces/TComponentParams';
import { HCPService } from '../services/HCPService';
import {
  RegionsService,
  TRegion,
  WORLD_REGION,
} from '../services/RegionsService';
import { AuthService, TCurrentUser } from '../services/auth/auth.service';
import { PagingService } from '../services/paging/PagingService';
import { TPagingItem } from '../services/paging/TPagingItem';

@Component({
  selector: 'app-hcps-view',
  templateUrl: './hcps-view.component.html',
  styleUrls: ['./hcps-view.component.scss'],
})
export class HcpsViewComponent
  implements OnInit, OnDestroy, IHandleCommonTable
{
  public dataSource: MatTableDataSource<THCPTableRow>;
  public hcps: THCP[] = [];
  public hcpsLoaded: boolean = false;
  public hcpsCount: number = 0;
  public columns: TDataColumnDef<THCP>[] = [
    ...buildColumnsDef({
      textColumns: [],
      fieldsMappings: Array.from(HCP_DB_MAPPINGS.values()).filter(
        (f) => f.visibleInSummary
      ),
    }),
  ];
  private _countries: TRegion[] = [];
  private _subs: Subscription[] = [];
  private searchTerm: string = ``;

  @Input()
  public selectedCategory: TCategory | undefined;
  @Input()
  public selectedProject: TProject | undefined;
  @Input()
  public onImportFinish: () => void = () => void 0;

  public pageEvent: PageEvent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(CommonTableComponent) table: CommonTableComponent;

  constructor(
    private _HCPsService: HCPService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _pagingService: PagingService,
    private _regionsService: RegionsService,
    private _authService: AuthService
  ) {
    this.dataSource = new MatTableDataSource();
    this._subs.push(
      this._HCPsService
        .getExtendedHCPsObservable()
        .subscribe((hcps: THCP[]) => {
          this.hcps = hcps;
          this._processTableData();
        })
    );
    this._subs.push(
      this._HCPsService.getExtendedHCPsCountObservable().subscribe((count) => {
        this.hcpsCount = count;
      })
    );
    this._subs.push(
      this._regionsService
        .getSelectedCountriesObservable()
        .subscribe((countries) => {
          if (
            countries.filter((c) => c.value === WORLD_REGION.value).length === 0
          ) {
            this._onRegionChange(countries);
          } else {
            this._onRegionChange([]);
          }
        })
    );
  }

  public ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public paginationCallback(e: PageEvent): void {
    if (this.selectedProject !== undefined) {
      const currentPaging: TPagingItem = structuredClone(
        this._pagingService.getPagingItem(this.selectedProject.id)
      );
      currentPaging.hcpsPaging.pageIndex = e.pageIndex;
      currentPaging.hcpsPaging.pageSize = e.pageSize;
      this._pagingService.updatePagingItem(
        this.selectedProject.id,
        currentPaging
      );
      this._setTableData(this.searchTerm);
    }
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public ngOnInit(): void {
    this.hcpsLoaded = true;
    if (this.selectedProject !== undefined) {
      this._HCPsService.setExtendedHCPsCount(this.selectedProject.hcpsCount);
      this._setTableData();
    }
  }

  public applyFilter(term: string) {
    this.searchTerm = term;
    if (term.length > 3) {
      this._setTableData(this.searchTerm);
    } else if (term.length === 0) {
      this._setTableData();
      this.searchTerm = ``;
    }
  }

  public getTableParams(): ICommonTableParams<TTableRowType, TTDataType> {
    //TODO investigate why this is calling multiple times
    const currentPaging: TPagingItem = this._pagingService.getPagingItem(
      this.selectedProject?.id ?? ``
    );
    return {
      columns: this.columns as TDataColumnDef<TTDataType>[],
      dataCount: currentPaging.hcpsPaging.total,
      dataLoaded: this.hcpsLoaded,
      dataSource: this.dataSource as MatTableDataSource<TTableRowType>,
      displayedColumns: Array.from(HCP_DB_MAPPINGS.values())
        .filter((f) => f.visibleInSummary)
        .map((f) => f.fieldId),
      handleEditClick: (row: TTableRowType) =>
        this._handleEditClick(<THCPTableRow>row),
      pageIndex: currentPaging.hcpsPaging.pageIndex,
      pageSize: currentPaging.hcpsPaging.pageSize,
      pageSizeOptions: this._pagingService.pageSizeOptions,
      paginationCallback: (e) => this.paginationCallback(e),
      downloadCallback: () => this.downloadHCPs(),
      importCallback: (e, mappings) => this.importHCPs(e, mappings),
      handleHeaderClick: (fieldId, remove) =>
        this._handleHeaderClick(fieldId, remove),
      projectId: this.selectedProject?.id ?? ``,
      area: SearchArea.HCP,
      filterCallback: (term: string) => this.applyFilter(term),
      addNewParams: {
        addNewCallback: () => this._handleAddNewHCP(),
        tooltip: `Add new HCP`,
      },
      resize: true,
      selection: true,
      actionsAvailable: true,
      sortType: SortType.Extended,
      resetTable: () => this._setTableData(),
      importEngagementCallback: (e, projectId) =>
        this.importEngagementCallback(e, projectId),
    };
  }

  private importEngagementCallback(e: Event, projectId: string): void {
    this._HCPsService.importEngagement(e, projectId, `true`);
  }

  private _handleAddNewHCP(): void {
    if (
      this.selectedCategory !== undefined &&
      this.selectedProject !== undefined
    ) {
      const hcpParams: THCPParams = {
        hcpId: NEW_HCP_ID,
        categoryId: this.selectedCategory.categoryId,
        projectId: this.selectedProject.id,
        __type: TComponentType.hcp,
      };
      this._router.navigate([`/${ROUTES_PATHS.hcp}`, hcpParams], {
        relativeTo: this._route,
      });
    }
  }

  private _handleHeaderClick(fieldId: string, remove: boolean): void {
    if (this.selectedProject !== undefined) {
      this._pagingService.handleSort({
        area: SearchArea.HCP,
        fieldId: fieldId,
        projectId: this.selectedProject.id,
        remove: remove,
      });
      this._setTableData(this.searchTerm);
    }
  }

  private importHCPs(e: Event, mappings: TImportMappingItem[]): void {
    if (
      this.selectedCategory !== undefined &&
      this.selectedProject !== undefined
    ) {
      this._HCPsService.importHCPs(
        e,
        () => this.onImportFinish(),
        this.selectedProject.id,
        mappings
      );
    }
  }

  private downloadHCPs(): void {
    if (
      this.selectedCategory !== undefined &&
      this.selectedProject !== undefined
    ) {
      this._HCPsService.downloadHCPs(
        this.selectedCategory.categoryId,
        this.selectedProject.id
      );
    }
  }

  private _handleEditClick(row: THCPTableRow): void {
    if (
      this.selectedCategory !== undefined &&
      this.selectedProject !== undefined
    ) {
      const hcpParams: THCPParams = {
        hcpId: row.id,
        categoryId: this.selectedCategory.categoryId,
        projectId: this.selectedProject.id,
        __type: TComponentType.hcp,
      };
      this._router.navigate([`/${ROUTES_PATHS.hcp}`, hcpParams], {
        relativeTo: this._route,
      });
    }
  }

  private _setTableData(query: string = ``): void {
    if (this.selectedProject !== undefined) {
      const currentPaging: TPagingItem = this._pagingService.getPagingItem(
        this.selectedProject.id
      );
      if (
        isNullOrEmpty(query) &&
        !isNullOrEmpty(this.table?.currentQuery) &&
        this.table?.currentQuery.length > 3
      ) {
        query = this.table.currentQuery;
      }
      if (!isNullOrEmpty(currentPaging.hcpsPaging.query)) {
        query = currentPaging.hcpsPaging.query;
      }
      const currentUser: TCurrentUser = this._authService.getCurrentUser();
      const isAdmin = currentUser?.level === UserLevel.Admin;
      const showAllCountries =
        isAdmin || (currentUser?.countries || []).length === 0;

      const params: TSearchParams<THCP> = {
        area: SearchArea.HCP,
        paging: currentPaging.hcpsPaging,
        projectId: this.selectedProject.id,
        query: query,
        results: [],
        countries: this._countries.map((c) => c.value),
        showAllCountries: showAllCountries,
      };
      this._HCPsService.searchAndSetHCPs(params, () => {
        this.table.searchModel = query;
      });
    }
  }

  private _processTableData(): void {
    const projectId: string = this.selectedProject?.id ?? ``;
    if (!isNullOrEmpty(projectId)) {
      this.dataSource.data = this.hcps.map((hcp, index) => {
        const extendedHCPprops: any = TableDataExtender.HCPTableDataExtender(
          hcp,
          projectId
        );
        return {
          ...extendedHCPprops,
          ...hcp,
          position: index,
          __type: TableRowType.HCP,
        };
      });
    }
  }

  private _onRegionChange(countries: TRegion[]): void {
    this._countries = countries;
    if (this.selectedProject !== undefined) {
      const currentPaging: TPagingItem = this._pagingService.getPagingItem(
        this.selectedProject.id
      );
      currentPaging.hcpsPaging.pageIndex = 0;
      this._pagingService.updatePagingItem(
        this.selectedProject.id,
        currentPaging
      );
    }
    this._setTableData(this.searchTerm);
  }
}
