<div class="flex pt-5 gap-4">
  <div class="w-2/5">
    <div *ngIf="isNew()" class="flex gap-2 items-center mb-2">
      <div class="flex w-full search-wrapper">
        <mat-form-field appearance="fill" class="w-full no-margin">
          <mat-label>Search existing Center</mat-label>
          <input
            type="text"
            placeholder="Type at least 3 letters"
            matInput
            [formControl]="centerControl"
            [matAutocomplete]="auto"
            [value]="existingCenterSearch"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option"
              (click)="fillCenter(option)"
            >
              {{ option.c_name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
      </div>
      <button mat-flat-button color="primary" mat-button (click)="clearData()">
        Clear data
      </button>
    </div>
    <!-- KEY INFO -->
    <div class="flex flex-col">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="centerData.c_name" />
      </mat-form-field>
      <app-autocomplete-wrapper
        #child
        [options]="countries"
        [label]="'Country'"
        [selectionChangeCallback]="onSelectionChange.bind(this)"
      ></app-autocomplete-wrapper>
      <div class="flex gap-4">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>City</mat-label>
          <input matInput [(ngModel)]="centerData.c_city" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>State</mat-label>
          <input matInput [(ngModel)]="centerData.c_state" />
        </mat-form-field>
      </div>
      <div class="flex gap-4">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Address</mat-label>
          <input matInput [(ngModel)]="centerData.c_address" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Zip code</mat-label>
          <input matInput [(ngModel)]="centerData.c_zip" />
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>website</mat-label>
        <input matInput [(ngModel)]="centerData.website" />
      </mat-form-field>
      <div class="flex gap-4">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Center ID</mat-label>
          <input matInput [(ngModel)]="centerData.c_US_systemID" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Center ID 2</mat-label>
          <input matInput [(ngModel)]="centerData.c_country_systemID2" />
        </mat-form-field>
      </div>
      <div class="flex gap-4">
        <mat-form-field class="w-full">
          <mat-label>{{ getFieldDescription("c_project_region1") }}</mat-label>
          <mat-select [(value)]="getProjectData().c_project_region1">
            <mat-option
              *ngFor="let region of availableRegions1"
              [value]="region.id"
            >
              {{ region.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
          <mat-label>{{ getFieldDescription("c_project_region2") }}</mat-label>
          <mat-select [(value)]="getProjectData().c_project_region2">
            <mat-option
              *ngFor="let region of availableRegions2"
              [value]="region.id"
            >
              {{ region.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="w-1/5 flex flex-col">
    <div class="flex mb-2 justify-between">
      <h3 class="mat-h3">Impact</h3>
      <app-score-view
        [title]="'Impact'"
        [score]="impactScore"
        [projectId]="centerParams.projectId"
        [area]="'centerImpact'"
        [position]="'static'"
      ></app-score-view>
    </div>

    <div class="pt-11">
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ getFieldDescription("c_decile") }}</mat-label>
        <input matInput [(ngModel)]="getProjectData().c_decile" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ getFieldDescription("c_beds") }}</mat-label>
        <input type="number" matInput [(ngModel)]="centerData.c_beds" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ getFieldDescription("c_inpatient_days") }}</mat-label>
        <input
          type="number"
          matInput
          [(ngModel)]="centerData.c_inpatient_days"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Center number of publications</mat-label>
        <input
          type="number"
          matInput
          [(ngModel)]="articlesCount"
          disabled="true"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Center number of clinical trials</mat-label>
        <input type="number" matInput [(ngModel)]="CTCount" disabled="true" />
      </mat-form-field>
    </div>
    <div>
      <div class="flex gap-4 justify-end">
        <mat-form-field appearance="fill" class="w-1/2">
          <mat-label>{{ getFieldDescription("c_proj_impact_tier") }}</mat-label>
          <mat-select [(value)]="getProjectData().c_proj_impact_tier">
            <mat-option
              *ngFor="let tier of getTiers('c_proj_impact_tier')"
              [value]="tier.name"
              >{{ tier.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="w-2/5 flex flex-col">
    <div class="flex gap-4">
      <div class="w-1/2">
        <div class="flex mb-2 justify-between">
          <h3 class="mat-h3">Potential</h3>
          <app-score-view
            [title]="'Potential'"
            [score]="centerPotentialScore"
            [projectId]="centerParams.projectId"
            [area]="'centerPotential'"
            [position]="'static'"
          ></app-score-view>
        </div>
      </div>
      <div class="w-1/2">
        <div class="flex mb-2 justify-between">
          <div class="flex flex-col">
            <h3 class="mat-h3">Utilisation</h3>
            <p class="text-blue">
              {{ formattedSale() }}
            </p>
          </div>
          <app-score-view
            [title]="'Utilisation'"
            [score]="centerUtilScore"
            [projectId]="centerParams.projectId"
            [area]="'centerUtil'"
            [position]="'static'"
          ></app-score-view>
        </div>
      </div>
    </div>
    <div class="w-full flex gap-4">
      <div class="w-full blue-background px-2 mb-2">
        <h3 class="mat-h3">KAM Input</h3>
        <div class="flex gap-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{
              getFieldDescription("c_proj_patient_potential1")
            }}</mat-label>
            <input
              type="number"
              matInput
              [(ngModel)]="getProjectData().c_proj_patient_potential1"
            />
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{
              getFieldDescription("patient_utilisation1")
            }}</mat-label>
            <input
              type="number"
              matInput
              [(ngModel)]="getProjectData().patient_utilisation1"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="w-full flex gap-4">
      <div class="w-1/2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{
            getFieldDescription("c_proj_patient_potential2")
          }}</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="getProjectData().c_proj_patient_potential2"
          />
        </mat-form-field>
      </div>
      <div class="w-1/2">
        <div class="flex gap-4 items-center">
          <mat-form-field appearance="fill">
            <mat-label>{{
              getFieldDescription("sales_latest_period")
            }}</mat-label>
            <input
              type="number"
              matInput
              [(ngModel)]="getProjectData().sales_latest_period"
              disabled="true"
            />
          </mat-form-field>
          <div
            *ngIf="
              latestSale &&
              prevSale &&
              latestSale.sales_count > prevSale.sales_count
            "
            class="w-12 h-12 flex justify-center items-center"
          >
            <mat-icon class="text-green-500">add</mat-icon>
          </div>
          <div
            *ngIf="
              latestSale &&
              prevSale &&
              latestSale.sales_count < prevSale.sales_count
            "
            class="w-12 h-12 flex justify-center items-center"
          >
            <mat-icon class="text-red-600">remove</mat-icon>
          </div>
          <div
            *ngIf="
              latestSale &&
              prevSale &&
              latestSale.sales_count === prevSale.sales_count
            "
            class="w-12 h-12 flex justify-center items-center"
          >
            <mat-icon>remove</mat-icon>
          </div>
          <div
            *ngIf="latestSale === undefined || prevSale === undefined"
            class="w-12 h-12 flex justify-center items-center"
          >
            <mat-icon>remove</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex gap-4">
      <div class="w-1/2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{
            getFieldDescription("c_proj_patient_potential3")
          }}</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="getProjectData().c_proj_patient_potential3"
          />
        </mat-form-field>
      </div>
      <div class="w-1/2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{
            getFieldDescription("sales_patient_equivalents")
          }}</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="getProjectData().sales_patient_equivalents"
            disabled="true"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="w-full flex gap-4">
      <div class="w-1/2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{
            getFieldDescription("c_proj_patient_potential4")
          }}</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="getProjectData().c_proj_patient_potential4"
          />
        </mat-form-field>
      </div>
      <div class="w-1/2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{
            getFieldDescription("sales_momentum_short")
          }}</mat-label>
          <input matInput [(ngModel)]="sales_momentum_short" disabled="true" />
        </mat-form-field>
      </div>
    </div>

    <div class="w-full flex gap-4">
      <div class="w-1/2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{
            getFieldDescription("c_proj_patient_potential5")
          }}</mat-label>
          <input
            type="number"
            matInput
            [(ngModel)]="getProjectData().c_proj_patient_potential5"
          />
        </mat-form-field>
      </div>
      <div class="w-1/2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>{{
            getFieldDescription("sales_momentum_long")
          }}</mat-label>
          <input matInput [(ngModel)]="sales_momentum_long" disabled="true" />
        </mat-form-field>
      </div>
    </div>

    <div class="w-full flex gap-4">
      <div class="w-1/2">
        <div class="flex gap-4 justify-end">
          <mat-form-field appearance="fill" class="w-1/2">
            <mat-label>{{
              getFieldDescription("c_proj_bu1_potential1")
            }}</mat-label>
            <mat-select [(value)]="getProjectData().c_proj_bu1_potential1">
              <mat-option
                *ngFor="let tier of getTiers('c_proj_bu1_potential1')"
                [value]="tier.name"
                >{{ tier.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="w-1/2">
        <div class="flex gap-4 justify-end">
          <mat-form-field appearance="fill" class="w-1/2">
            <mat-label>{{ getFieldDescription("c_proj_bu1_use1") }}</mat-label>
            <mat-select [(value)]="getProjectData().c_proj_bu1_use1">
              <mat-option
                *ngFor="let tier of getTiers('c_proj_bu1_use1')"
                [value]="tier.name"
                >{{ tier.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ACCOUNT PLAN -->
<div class="w-full blue-background p-2">
  <h3 class="mat-h3">Account Plan</h3>
  <div class="flex gap-4 w-full">
    <div class="flex flex-col gap-4 w-3/5">
      <mat-form-field appearance="fill" class="w-full !h-32">
        <mat-label>Account vision</mat-label>
        <textarea
          class="!h-24"
          id="no-save-on-enter--1"
          matInput
          placeholder="Account vision"
          [(ngModel)]="getProjectData().center_vision"
        ></textarea>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-full !h-40">
        <mat-label
          >Compliant SMART objectives (Who, What, with Whom, by When)</mat-label
        >
        <textarea
          class="!h-32"
          id="no-save-on-enter--4"
          matInput
          placeholder="Compliant SMART objectives (Who, What, with Whom, by When)"
          [(ngModel)]="getProjectData().center_objectives"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="flex gap-4 w-2/5">
      <mat-form-field appearance="fill" class="w-full !h-80">
        <mat-label
          >What accelerates short term growth (supporting factors)?</mat-label
        >
        <textarea
          id="no-save-on-enter--2"
          class="!h-72"
          matInput
          placeholder="What accelerates short term growth (supporting factors)?"
          [(ngModel)]="getProjectData().center_growth_driver"
        ></textarea>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-full !h-80">
        <mat-label
          >What hinders short term growth (hindering factors)?</mat-label
        >
        <textarea
          id="no-save-on-enter--3"
          class="!h-72"
          matInput
          placeholder="What hinders short term growth (hindering factors)?"
          [(ngModel)]="getProjectData().center_growth_obstacle"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
<!-- BOTTOM PART -->
<div class="flex gap-4 blue-background p-2 mt-4">
  <div class="flex w-3/5 gap-4">
    <!-- Treatment goals -->
    <div class="w-3/5 min-w-[360px]">
      <h3 class="mat-h3">Treatment goals</h3>
      <div class="account-plan-area p-1">
        <p class="mb-4 text-gray">
          Treatment objectives prioritized by the physicians at this center
        </p>
        <div
          *ngFor="let tg of availableTreatmentGoals; index as itg"
          class="flex gap-4 items-center h-10 mt-1"
        >
          <mat-checkbox
            *ngIf="this.getProjectData().treatment_goals_enabled"
            class="mt-2"
            [(ngModel)]="getProjectData().treatment_goals_enabled[itg]"
          >
          </mat-checkbox>
          <p class="mt-2 text-gray">
            {{ tg }}
          </p>
        </div>
      </div>
    </div>
    <!-- Therapy - Treatment used -->
    <div class="w-2/5 min-w-[320px]">
      <h3 class="mat-h3">Treatment used</h3>
      <div class="account-plan-area p-1">
        <p class="text-gray mb-4">Therapies used in Center</p>
        <table>
          <tr>
            <th class="th-left text-gray font-normal">#</th>
            <th class="th-left w-20 text-gray font-normal">validated</th>
            <th class="th-left text-gray font-normal">Therapy</th>
          </tr>
          <tr>
            <td>
              <input
                class="w-10 text-gray"
                type="number"
                matInput
                disabled="true"
                [(ngModel)]="getProjectData().treatment_option_total_count"
              />
            </td>
            <td></td>
            <td class="h-10 text-gray">Total</td>
          </tr>
          <tr *ngFor="let to of availableTreatmentOptions; index as ito">
            <td>
              <input
                *ngIf="this.getProjectData().treatment_options_counts"
                class="w-10"
                type="number"
                matInput
                (ngModelChange)="onTherapyChange($event, ito)"
                [(ngModel)]="getProjectData().treatment_options_counts[ito]"
              />
            </td>
            <td>
              <mat-checkbox
                *ngIf="this.getProjectData().treatment_options_validated"
                [(ngModel)]="getProjectData().treatment_options_validated[ito]"
              >
              </mat-checkbox>
            </td>
            <td>
              <p class="text-gray">
                {{ to }}
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="flex w-2/5 gap-4">
    <!-- used in patients -->
    <div class="w-1/2 min-w-[240px]">
      <h3 class="mat-h3">{{ project?.name || "" }} used in patients</h3>
      <div class="account-plan-area p-1">
        <p class="text-gray mb-4">Patient profiles treated</p>
        <div
          *ngFor="let tu of availableTreatmentUses; index as itu"
          class="flex gap-4 items-center h-10 mt-1"
        >
          <mat-checkbox
            *ngIf="this.getProjectData().treatment_uses_enabled"
            class="mt-2"
            [(ngModel)]="getProjectData().treatment_uses_enabled[itu]"
          >
          </mat-checkbox>
          <p class="mt-2 text-gray">
            {{ tu }}
          </p>
        </div>
      </div>
    </div>
    <!-- Decision makers -->
    <div class="w-1/2 min-w-[240px]">
      <h3 class="mat-h3">Decision makers</h3>
      <div class="decision-makers p-1">
        <p class="text-gray">
          Who are the relevant decision makers and influencers?
        </p>
      </div>
      <mat-form-field appearance="fill" class="w-full !h-[360px]">
        <textarea
          id="no-save-on-enter--5"
          class="!h-[336px]"
          matInput
          placeholder="Center decision makers"
          [(ngModel)]="getProjectData().center_decision_makers"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="flex gap-4 blue-background p-2">
  <mat-form-field appearance="fill" class="w-full !h-36">
    <mat-label>Latest center news</mat-label>
    <textarea
      id="no-save-on-enter--6"
      class="!h-28"
      matInput
      placeholder="Latest center news"
      [(ngModel)]="getProjectData().centerNews"
    ></textarea>
  </mat-form-field>
</div>
<!-- TABLES -->
<app-hcps-table
  [centerParams]="centerParams"
  [centerData]="centerData"
></app-hcps-table>
<app-sales-table
  [centerParams]="centerParams"
  [centerData]="centerData"
></app-sales-table>
