import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import { SearchArea } from '@shared/enums/SearchArea';
import { TCategory } from '@shared/interfaces/TCategory';
import { TCenter } from '@shared/interfaces/TCenter';
import { TImportMappingItem } from '@shared/interfaces/TImportMappingItem';
import { TProject } from '@shared/interfaces/TProject';
import { TSearchParams } from '@shared/interfaces/TSearchParams';
import { UserLevel } from '@shared/interfaces/TUser';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Subscription } from 'rxjs';
import { TDataColumnDef, buildColumnsDef } from 'src/utils/buildColumnsDef';
import { ROUTES_PATHS } from '../app-routing.module';
import { NEW_CENTER_ID } from '../center/center.component';
import { CommonTableComponent } from '../common-table/common-table.component';
import {
  ICommonTableParams,
  IHandleCommonTable,
  SortType,
  TCenterTableRow,
  TTDataType,
  TTableRowType,
  TableRowType,
} from '../common-table/common-table.component.types';
import { TableDataExtender } from '../common-table/utils/TableDataExtender';
import { TCenterParams, TComponentType } from '../interfaces/TComponentParams';
import { CentersService } from '../services/CentersService';
import {
  RegionsService,
  TRegion,
  WORLD_REGION,
} from '../services/RegionsService';
import { AuthService, TCurrentUser } from '../services/auth/auth.service';
import { PagingService } from '../services/paging/PagingService';
import { TPagingItem } from '../services/paging/TPagingItem';

@Component({
  selector: 'app-centers-review',
  templateUrl: './centers-review.component.html',
  styleUrls: ['./centers-review.component.scss'],
})
export class CentersReviewComponent
  implements OnInit, IHandleCommonTable, OnDestroy
{
  public dataSource: MatTableDataSource<TCenterTableRow>;
  public centers: TCenter[] = [];
  public centersLoaded: boolean = false;
  public centersCount: number = 0;
  public columns: TDataColumnDef<TCenter>[] = [
    ...buildColumnsDef({
      textColumns: [],
      fieldsMappings: Array.from(CENTER_DB_MAPPINGS.values()).filter(
        (f) => f.visibleInSummary
      ),
    }),
  ];
  public tableParams: ICommonTableParams<TTableRowType, TTDataType>;
  @Input() public selectedCategory: TCategory | undefined;
  @Input() public selectedProject: TProject | undefined;
  @Input() public onImportFinish: () => void = () => void 0;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(CommonTableComponent) table: CommonTableComponent;

  private _subs: Subscription[] = [];
  private _countries: TRegion[] = [];
  private searchTerm: string = ``;

  constructor(
    private _centersService: CentersService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _pagingService: PagingService,
    private _regionsService: RegionsService,
    private _authService: AuthService
  ) {
    this.dataSource = new MatTableDataSource();
    this._subs.push(
      this._centersService
        .getCentersObservable()
        .subscribe((centers: TCenter[]) => {
          this.centers = centers;
          this._processTableData();
        })
    );
    this._subs.push(
      this._centersService
        .getCentersCountObservable()
        .subscribe((centersCount) => {
          this.centersCount = centersCount;
        })
    );
    this._subs.push(
      this._regionsService
        .getSelectedCountriesObservable()
        .subscribe((countries) => {
          if (
            countries.filter((c) => c.value === WORLD_REGION.value).length === 0
          ) {
            this._onRegionChange(countries);
          } else {
            this._onRegionChange([]);
          }
        })
    );
  }

  public ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public ngOnInit(): void {
    this.centersLoaded = true;
    this._setTableData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.tableParams = this.getTableParams();
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public paginationCallback(e: PageEvent): void {
    if (this.selectedProject !== undefined) {
      const currentPaging: TPagingItem = structuredClone(
        this._pagingService.getPagingItem(this.selectedProject.id)
      );
      currentPaging.centersPaging.pageIndex = e.pageIndex;
      currentPaging.centersPaging.pageSize = e.pageSize;
      this._pagingService.updatePagingItem(
        this.selectedProject.id,
        currentPaging
      );
      this._setTableData();
    }
  }

  public applyFilter(term: string) {
    this.searchTerm = term;
    if (term.length > 3) {
      this._setTableData(this.searchTerm);
      this.dataSource?.paginator?.firstPage();
    } else if (term.length === 0) {
      this._setTableData();
      this.searchTerm = ``;
      this.dataSource?.paginator?.firstPage();
    }
  }

  public isDataColumn<T>(column: TDataColumnDef<T>): boolean {
    return column.isDataCell === true;
  }

  public getTableParams(): ICommonTableParams<TTableRowType, TTDataType> {
    const currentPaging: TPagingItem = this._pagingService.getPagingItem(
      this.selectedProject?.id ?? ``
    );
    return {
      columns: this.columns as TDataColumnDef<TTDataType>[],
      dataCount: currentPaging.centersPaging.total,
      dataLoaded: this.centersLoaded,
      dataSource: this.dataSource as MatTableDataSource<TTableRowType>,
      displayedColumns: Array.from(CENTER_DB_MAPPINGS.values())
        .filter((f) => f.visibleInSummary)
        .map((f) => f.fieldId),
      handleEditClick: (row: TTableRowType) =>
        this._handleEditClick(<TCenterTableRow>row),
      pageIndex: currentPaging.centersPaging.pageIndex,
      pageSize: currentPaging.centersPaging.pageSize,
      pageSizeOptions: this._pagingService.pageSizeOptions,
      paginationCallback: (e) => this.paginationCallback(e),
      downloadCallback: () => this.downloadCenters(),
      importCallback: (e, mappings) => this.importCenters(e, mappings),
      area: SearchArea.Centers,
      projectId: this.selectedProject?.id ?? ``,
      filterCallback: (term: string) => this.applyFilter(term),
      addNewParams: {
        addNewCallback: () => this._handleAddNewCenter(),
        tooltip: `Add new center`,
      },
      handleHeaderClick: (fieldId, remove) =>
        this._handleHeaderClick(fieldId, remove),
      resize: true,
      selection: true,
      actionsAvailable: true,
      sortType: SortType.Extended,
      resetTable: () => this._setTableData(),
    };
  }

  private _handleHeaderClick(fieldId: string, remove: boolean): void {
    if (this.selectedProject !== undefined) {
      this._pagingService.handleSort({
        area: SearchArea.Centers,
        fieldId: fieldId,
        projectId: this.selectedProject.id,
        remove: remove,
      });
      this._setTableData();
    }
  }

  private _handleAddNewCenter(): void {
    if (
      this.selectedCategory !== undefined &&
      this.selectedProject !== undefined
    ) {
      const centerParams: TCenterParams = {
        centerId: NEW_CENTER_ID,
        categoryId: this.selectedCategory.categoryId,
        projectId: this.selectedProject.id,
        __type: TComponentType.center,
      };
      this._router.navigate([`/${ROUTES_PATHS.center}`, centerParams], {
        relativeTo: this._route,
      });
    }
  }

  private importCenters(e: Event, mappings: TImportMappingItem[]): void {
    if (
      this.selectedCategory !== undefined &&
      this.selectedProject !== undefined
    ) {
      this._centersService.importCenters(
        e,
        () => this.onImportFinish(),
        this.selectedProject.id,
        mappings
      );
    }
  }

  private downloadCenters(): void {
    if (
      this.selectedCategory !== undefined &&
      this.selectedProject !== undefined
    ) {
      this._centersService.downloadCenters(
        this.selectedCategory.categoryId,
        this.selectedProject.id
      );
    }
  }

  private _handleEditClick(row: TCenterTableRow): void {
    if (
      this.selectedCategory !== undefined &&
      this.selectedProject !== undefined
    ) {
      const centerParams: TCenterParams = {
        centerId: row.c_id,
        categoryId: this.selectedCategory.categoryId,
        projectId: this.selectedProject.id,
        __type: TComponentType.center,
      };
      this._router.navigate([`/${ROUTES_PATHS.center}`, centerParams], {
        relativeTo: this._route,
      });
    }
  }

  private _setTableData(query: string = ``): void {
    if (
      this.selectedProject !== undefined &&
      this.selectedCategory !== undefined
    ) {
      const currentPaging: TPagingItem = this._pagingService.getPagingItem(
        this.selectedProject.id
      );
      if (isNullOrEmpty(query) && !isNullOrEmpty(this.table?.currentQuery)) {
        query = this.table.currentQuery;
      }
      if (!isNullOrEmpty(currentPaging.centersPaging.query)) {
        query = currentPaging.centersPaging.query;
      }
      const currentUser: TCurrentUser = this._authService.getCurrentUser();
      const isAdmin = currentUser?.level === UserLevel.Admin;
      const showAllCountries =
        isAdmin || (currentUser?.countries || []).length === 0;

      const params: TSearchParams<TCenter> = {
        area: SearchArea.Centers,
        paging: currentPaging.centersPaging,
        projectId: this.selectedProject.id,
        query: query,
        results: [],
        countries: this._countries.map((c) => c.value),
        showAllCountries,
      };
      this._centersService.searchAndSetCenters(params, () => {
        this.table.searchModel = query;
      });
    }
    this._processTableData();
  }

  private _processTableData(): void {
    if (!isNullOrUndefined(this.selectedProject)) {
      const dataColumns: TCenterTableRow[] = this.centers.map(
        (center, index) => {
          const extendedCenterProps: any =
            TableDataExtender.CenterTableDataExtender(
              center,
              this.selectedProject!.id
            );
          return {
            ...extendedCenterProps,
            ...center,
            position: index + 1,
            __type: TableRowType.Center,
          };
        }
      );
      this.dataSource.data = dataColumns;
    }
  }

  private _onRegionChange(countries: TRegion[]): void {
    this._countries = countries;
    if (this.selectedProject !== undefined) {
      const currentPaging: TPagingItem = this._pagingService.getPagingItem(
        this.selectedProject.id
      );
      currentPaging.centersPaging.pageIndex = 0;
      this._pagingService.updatePagingItem(
        this.selectedProject.id,
        currentPaging
      );
    }
    this._setTableData(this.searchTerm);
  }
}
