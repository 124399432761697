import { Injectable } from '@angular/core';
import { COUNTRIES } from '@shared/geonames.consts';
import { TSettings } from '@shared/interfaces/TSettings';
import { UserLevel } from '@shared/interfaces/TUser';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from './SettingsService';
import { AuthService, TCurrentUser } from './auth/auth.service';

export interface TRegion {
  value: string;
  viewValue: string;
}

export const WORLD_REGION: TRegion = { value: `World`, viewValue: `World` };
const mockRegions: TRegion[] = [
  WORLD_REGION,
  ...COUNTRIES.map((c) => {
    return { value: c, viewValue: c };
  }),
];

@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  private _selectedCountries: BehaviorSubject<TRegion[]> = new BehaviorSubject<
    TRegion[]
  >([WORLD_REGION]);
  private _countries: BehaviorSubject<TRegion[]> = new BehaviorSubject<
    TRegion[]
  >([]);
  private _allCountries: BehaviorSubject<TRegion[]> = new BehaviorSubject<
    TRegion[]
  >(mockRegions);
  private countriesOnly: TRegion[] = COUNTRIES.map((c) => {
    return { value: c, viewValue: c };
  });
  private currentUser: TCurrentUser;
  private settings: TSettings;

  private regionSelectorValues: BehaviorSubject<TRegion[]> =
    new BehaviorSubject<TRegion[]>([]);

  constructor(
    private settingsService: SettingsService,
    private _authService: AuthService
  ) {
    this._authService.getCurrentUserObservable().subscribe((user) => {
      this.currentUser = user;
      this.updateRegionSelectorValues();
    });
    this.settingsService.getAppSettingsObservable().subscribe((s) => {
      this.settings = s;
      this.updateRegionSelectorValues();
    });
  }

  private updateRegionSelectorValues(): void {
    if (!this.currentUser) return; // Wait for user data

    const customRegions =
      this.settings?.customRegions.map((r) => ({
        value: r.name,
        viewValue: r.name,
      })) || [];

    const allCountries: TRegion[] = [
      WORLD_REGION,
      ...customRegions,
      ...this.countriesOnly,
    ];

    if (this.currentUser.level !== UserLevel.Admin) {
      // user
      let allowedCountries = this.currentUser.countries || [];
      let countries = allCountries;
      if (
        allowedCountries.length &&
        !allowedCountries.includes(WORLD_REGION.value)
      ) {
        countries = countries.filter((c) => allowedCountries.includes(c.value));
        if (
          this._selectedCountries.value.length === 1 &&
          this._selectedCountries.value[0].value === WORLD_REGION.value
        ) {
          this.setSelectedCountries(countries);
        }
      }
      this.regionSelectorValues.next(countries);
      this._countries.next(countries);
    } else {
      // admin
      this.regionSelectorValues.next(allCountries);
      this._countries.next(allCountries);
    }
  }

  public getSelectedCountriesObservable(): Observable<TRegion[]> {
    return this._selectedCountries.asObservable();
  }

  public getSelectedCountries(): TRegion[] {
    return this._selectedCountries.value;
  }

  public getCountriesObservable(): Observable<TRegion[]> {
    return this._countries.asObservable();
  }

  public getCountries(): TRegion[] {
    return this._countries.value;
  }

  public getCountriesOnly(): TRegion[] {
    return this.countriesOnly;
  }

  public setSelectedCountries(region: TRegion[]): void {
    this._selectedCountries.next(region);
  }

  public getRegionSelectorValues(): Observable<TRegion[]> {
    return this.regionSelectorValues.asObservable();
  }

  public getAllCountries(): TRegion[] {
    return this._allCountries.value;
  }
}
