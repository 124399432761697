import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { TProject, TTierItem } from '@shared/interfaces/TProject';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { CategoriesService } from 'src/app/services/CategoriesService';
import { LoadingService } from 'src/app/services/LoadingService';
import { ScoreArea } from '../Constants';
import { TBaseSettingsTabParams } from '../project-settings.component';

@Component({
  selector: 'app-tier-settings',
  templateUrl: './tier-settings.component.html',
  styleUrls: ['./tier-settings.component.scss'],
})
export class TierSettingsComponent implements OnInit {
  @Input() public params: TBaseSettingsTabParams;
  @Input() public scoreArea: ScoreArea;

  public newTierItem: TTierItem = {
    high: 10,
    low: 0,
    name: ``,
    total: 0,
    position: 0,
  };
  public tiers: TTierItem[] = [];

  constructor(
    private _categoriesService: CategoriesService,
    private _loadingService: LoadingService
  ) {}

  public ngOnInit(): void {
    this.tiers = this.getExistingTiers();
  }

  public recalculateTotals(): void {
    switch (this.scoreArea) {
      case `center`:
      case 'centerImpact':
      case 'centerPotential':
      case 'centerUtil':
        if (
          !isNullOrUndefined(this.params.selectedCategory) &&
          !isNullOrUndefined(this.params.selectedProject)
        ) {
          this._categoriesService.updateProject(
            this.params.selectedCategory.categoryId,
            this.params.selectedProject,
            () => {
              this._categoriesService.updateCenterScores({
                categoryId: this.params.selectedCategory!.categoryId,
                projectId: this.params.selectedProject!.id,
                recalculateTotals: true,
                callback: () => {
                  this._categoriesService.setCategories(() => {
                    this.params.selectedProject =
                      this._categoriesService.getSelectedProject();
                    this.tiers = this.getExistingTiers();
                  });
                  this._loadingService.triggerOkMessage(
                    `Center scores updated`
                  );
                },
              });
            }
          );
        }
        break;
      case `hcpEngagement`:
      case `hcpInfluence`:
      case `hcpValue`:
      case `hcpProductUse`:
      case `risingStar`:
        if (
          !isNullOrUndefined(this.params.selectedCategory) &&
          !isNullOrUndefined(this.params.selectedProject)
        ) {
          this._categoriesService.updateProject(
            this.params.selectedCategory.categoryId,
            this.params.selectedProject,
            () => {
              this._categoriesService.updateHCPsScores({
                categoryId: this.params.selectedCategory!.categoryId,
                projectId: this.params.selectedProject!.id,
                recalculateTotals: true,
                callback: () => {
                  this._categoriesService.setCategories(() => {
                    this.params.selectedProject =
                      this._categoriesService.getSelectedProject();
                    this.tiers = this.getExistingTiers();
                  });
                  this._loadingService.triggerOkMessage(`HCPs scores updated`);
                },
              });
            }
          );
        }
        break;
      case `clinicalTrials`:
        break;
    }
  }

  public getExistingTiers(): TTierItem[] {
    return (
      this.params.selectedProject?.tiersDefinitions?.[this.scoreArea] ?? []
    );
  }

  public addNewTier(tierItem: TTierItem): void {
    const newTierItem: TTierItem = structuredClone(tierItem);
    tierItem.name = ``;
    this._fixMissingProjectsData(this.params.selectedProject);
    this.params.selectedProject?.tiersDefinitions?.[this.scoreArea].push(
      newTierItem
    );
  }

  private _fixMissingProjectsData(project: TProject | undefined): void {
    if (
      !isNullOrUndefined(project) &&
      isNullOrUndefined(project.tiersDefinitions[this.scoreArea])
    ) {
      project.tiersDefinitions[this.scoreArea] = [];
    }
  }

  private _removeTier(tiers: TTierItem[], item: TTierItem): void {
    const index: number = tiers.findIndex((t) => t.name === item.name);
    if (index >= 0) {
      tiers.splice(index, 1);
    }
  }

  public deleteTier(tierItem: TTierItem): void {
    this._removeTier(
      this.params.selectedProject?.tiersDefinitions?.[this.scoreArea] ?? [],
      tierItem
    );
  }

  public onDrop(event: CdkDragDrop<TTierItem[]>): void {
    moveItemInArray(
      this.getExistingTiers(),
      event.previousIndex,
      event.currentIndex
    );
  }
}
