import { SearchArea } from '@shared/enums/SearchArea';
import { TCenter } from './TCenter';
import { THCP } from './THCP';

export enum Order {
  Asc = -1,
  Desc = 1
}

export interface TSortItem {
  fieldId: string;
  order: Order;
}

export interface TPaging {
  pageSize: number;
  pageIndex: number;
  total: number;
  sortBy: TSortItem[];
  query: string;
}

export interface TSearchParams<T> {
  projectId: string;
  area: SearchArea;
  query: string;
  paging: TPaging;
  results: T[];
  countries?: string[];
  showAllCountries: boolean;
  select?: any;
  fromDate?: string;
  projectRegions?: {
    filter: boolean;
    filterBy: string[];
  };
}

export interface TReportHCPResults {
  hcps: THCP[];
  paging: TPaging;
}

export interface TReportCentersResults {
  centers: TCenter[];
  paging: TPaging;
}
