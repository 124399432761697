import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedConstants } from '@shared/Constants';
import { Endpoints } from '@shared/Endpoints';
import { SearchArea } from '@shared/enums/SearchArea';
import {
  THCP,
  THCPProjectsData,
  buildHCPMock,
  buildProjectDataHCPMock,
} from '@shared/interfaces/THCP';
import { THCPUpdateParams } from '@shared/interfaces/THCPUpdateParams';
import { TImportMappingItem } from '@shared/interfaces/TImportMappingItem';
import { THCPMergeRowsParams } from '@shared/interfaces/TMergeRowsParams';
import { TReportQuery } from '@shared/interfaces/TReportQuery';
import {
  TPaging,
  TReportHCPResults,
  TSearchParams,
} from '@shared/interfaces/TSearchParams';
import { TUpdateHCP } from '@shared/interfaces/TUpdateHCP';
import { TUpdateHCPResponse } from '@shared/interfaces/TUpdateHCPResponse';
import { UserLevel } from '@shared/interfaces/TUser';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import saveAs from 'file-saver';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CacheService, TCTCacheItem } from './CacheService';
import { CategoriesService } from './CategoriesService';
import { LoadingService, TLoadingItem } from './LoadingService';
import { RegionsService, WORLD_REGION } from './RegionsService';
import { RiseAppApiService } from './RiseAppApiService';
import { SSEService } from './SSEService';
import { SettingsService } from './SettingsService';
import { AuthService, TCurrentUser } from './auth/auth.service';
import { PagingService } from './paging/PagingService';
import { TPagingItem } from './paging/TPagingItem';

interface TSetHCPsParams {
  categoryId: string;
  projectId: string;
  ids: string[];
}

@Injectable()
export class HCPService {
  private _extendedHCPsCount: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private _HCPs: BehaviorSubject<THCP[]> = new BehaviorSubject<THCP[]>([]);

  constructor(
    private _loadingService: LoadingService,
    private _http: HttpClient,
    private _regionsService: RegionsService,
    private _pagingService: PagingService,
    private _categoriesService: CategoriesService,
    private _settingsService: SettingsService,
    private _riseApi: RiseAppApiService,
    private _SSEService: SSEService,
    private _cacheService: CacheService,
    private _authService: AuthService
  ) {
    this._riseApi.setLogCurrentHCPs(() => {
      return this._HCPs.value;
    });
  }

  public getHCPsObservable(): Observable<THCP[]> {
    return this._HCPs.asObservable();
  }

  public getExtendedHCPsCountObservable(): Observable<number> {
    return this._extendedHCPsCount.asObservable();
  }

  public getProjectData(hcp: THCP, projectId: string): THCPProjectsData {
    const newProjectData: THCPProjectsData = buildProjectDataHCPMock({
      projectId,
    });
    const projectData = hcp.projectsData.find((p) => p.projectId === projectId);
    if (isNullOrUndefined(projectData)) {
      hcp.projectsData.push(newProjectData);
      return newProjectData;
    }
    return projectData;
  }

  public setNewProjectData(hcp: THCP, newProjectData: THCPProjectsData): void {
    hcp.projectsData.push(newProjectData);
  }

  public setExtendedHCPsCount(count: number): void {
    this._extendedHCPsCount.next(count);
  }

  public getExtendedHCPsObservable(): Observable<THCP[]> {
    return this._HCPs.asObservable();
  }

  public confirmOptIn(hcp: THCP, hash: string, callback: () => void): void {
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'confirmOptIn',
      params: `?hcp=${hcp.id}&hash=${hash}`,
    });
    const response = this._http.get(url);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: () => {
        callback();
      },
    });
  }

  public batchUpdate(data: TUpdateHCP[]): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Updating HCPs`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'hcpBatchUpdate',
      params: ``,
    });
    const response = this._http.post<{ data: TUpdateHCP[] }>(url, { data });
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: () => {
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public setNormalizationIndex(projectId: string): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Normalizing index`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('score', {
      base: environment.apiUrl,
      endpoint: 'setHCPNormalizationIndex' as any,
      params: `?categoryId=''&projectId=${projectId}`,
    });
    const response = this._http.get(url);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: () => {
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public migrateHCPs(): void {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: `migrateHCPs`,
      params: ``,
    });
    this._http.get<{ status: string }>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        console.log(response);
      },
    });
  }

  public getHCPsForCenter(
    centerId: string,
    projectId: string,
    callback: (hcps: THCP[]) => void
  ): void {
    const loadingItem: TLoadingItem = this._loadingService.buildLoadingItem(
      `Fetching HCPs for center`
    );
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: `getHCPsForCenter`,
      params: `?centerId=${centerId}`,
    });
    this._http.get<THCP[]>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        callback(response);
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  private updateWithDefaults(hcps: THCP[]): THCP[] {
    return hcps.map((hcp) => {
      return {
        ...buildHCPMock(hcp),
      };
    });
  }

  public searchAndSetHCPs(
    params: TSearchParams<THCP>,
    callback?: (response: TSearchParams<THCP>) => void
  ): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching HCPs`);
    this._loadingService.addLoadingItem(loadingItem);

    const projectRegions = this.buildProjectRegions(params.projectId);
    params.projectRegions = projectRegions;
    const regions: string[] = this._regionsService
      .getSelectedCountries()
      .map((r) => r.value);

    const { showAllCountries, countries } = this.buildCountries(regions);
    params.countries = countries;
    params.showAllCountries = showAllCountries;

    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'searchHcps',
      params: ``,
    });
    const response = this._http.post<TSearchParams<THCP>>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        const currentPaging: TPagingItem = this._pagingService.getPagingItem(
          params.projectId
        );
        currentPaging.hcpsPaging = res.paging;
        this._pagingService.updatePagingItem(params.projectId, currentPaging);
        this._HCPs.next(this.updateWithDefaults(res.results));
        if (callback) callback(res);
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public setHCPs(params: TSetHCPsParams, callback?: () => void): void {
    const { ids, categoryId, projectId } = params;
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching HCPs`);
    this._loadingService.addLoadingItem(loadingItem);
    const idsParam: string = ids.join(SharedConstants.IDS_SPLIT);
    //TODO check if needed
    const type = 'hcpInfluence';
    const regions: string[] = this._regionsService
      .getSelectedCountries()
      .map((r) => r.value);
    const countries: string = regions.includes(WORLD_REGION.value)
      ? ``
      : regions.join(SharedConstants.IDS_SPLIT);
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: `getHCPS`,
      params: `?categoryId=${categoryId}&ids=${idsParam}&projectId=${projectId}&type=${type}&countries=${countries}`,
    });
    this._http.get<THCP[]>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (results) => {
        this._HCPs.next(results);
        if (callback !== undefined) {
          callback();
        }
        if (ids.length !== results.length) {
          const resultsIds: string[] = results.map((hcp) => hcp.id);
          console.warn(
            `Returned data missing ${ids
              .filter((id) => resultsIds.includes(id))
              .join(`, `)}`
          );
        }
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public downloadHCPs(categoryId: string, projectId: string): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Downloading HCPs`);
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService._handleGenerateExcelSSE(() => {
      this._loadingService.removeItem(loadingItem);
    });
    const url: string = Endpoints.BUILD_URL(`export`, {
      base: environment.apiUrl,
      endpoint: `hcps`,
      params: `?categoryId=${categoryId}&projectId=${projectId}`,
    });
    this._http.get(url, { responseType: 'arraybuffer' }).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (results) => {
        const projectName: string =
          this._categoriesService.getProject(projectId)?.name ?? projectId;
        const blob = new Blob([results], { type: 'application/octet-stream' });
        const fileName = `${projectName}-HCPs.csv`;
        this._loadingService.removeItem(loadingItem);
        saveAs(blob, fileName);
      },
    });
  }

  public importHCPs(
    e: Event,
    callback: () => void,
    projectId: string,
    mappings: TImportMappingItem[]
  ): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Importing HCPs`);
    this._loadingService.addLoadingItem(loadingItem);
    const files: FileList | null = target.files;
    const url: string = Endpoints.BUILD_URL('import', {
      base: environment.apiUrl,
      endpoint: 'hcps',
      params: ``,
    });
    const formData = new FormData();
    formData.append('file', files![0]);
    formData.append(`projectId`, projectId);
    formData.append(`mappings`, JSON.stringify(mappings));
    const response = this._http.post<string[]>(url, formData);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        callback();
        this._categoriesService.setCategories();
        this._loadingService.removeItem(loadingItem);
        this._loadingService.triggerOkMessage(`Importing HCPs finished`);
      },
    });
  }

  public updateHCP(
    hcpExtended: THCP,
    categoryId: string,
    projectId: string,
    callback?: () => void
  ): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Updating HCP`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL('score', {
      base: environment.apiUrl,
      endpoint: 'updateSingleHCPWithScore',
      params: ``,
    });
    const payload: THCPUpdateParams = {
      projectId: projectId,
      update: buildHCPMock(hcpExtended),
    };
    const response = this._http.post<TUpdateHCPResponse>(url, payload);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        if (response?.hcpId.length > 0) {
          this.setHCPs({
            categoryId: categoryId,
            ids: [response.hcpId],
            projectId: projectId,
          });
          if (callback !== undefined) {
            callback();
          }
        }
        this._loadingService.removeItem(loadingItem);
        this._loadingService.triggerOkMessage(
          `${payload.update.first_name} ${payload.update.last_name} updated`
        );
      },
    });
  }

  public downloadHCPFromReport(params: TReportQuery): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Downloading HCPs`);
    this._loadingService.addLoadingItem(loadingItem);
    this._SSEService._handleGenerateExcelSSE(() => {
      this._loadingService.removeItem(loadingItem);
    });
    const url: string = Endpoints.BUILD_URL(`export`, {
      base: environment.apiUrl,
      endpoint: `hcpsReport`,
      params: ``,
    });
    this._http.post(url, params, { responseType: 'arraybuffer' }).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (results) => {
        const projectName: string =
          this._categoriesService.getProject(params.projectId)?.name ??
          params.projectId;
        const blob = new Blob([results], { type: 'application/octet-stream' });
        const fileName = `${projectName}-HCPs-report.csv`;
        this._loadingService.removeItem(loadingItem);
        saveAs(blob, fileName);
      },
    });
  }

  public mergeRows(params: THCPMergeRowsParams, callback: () => void): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Merging HCPs`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'mergeHCPs',
      params: ``,
    });
    const response = this._http.post<THCP>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        this._loadingService.triggerOkMessage(
          `HCP - ${response.first_name} ${response.last_name} merged`
        );
        callback();
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public setHCPsFromReport(
    params: TReportQuery,
    callback: (paging: TPaging) => void
  ): void {
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Fetching HCPs`);
    this._loadingService.addLoadingItem(loadingItem);
    const url: string = Endpoints.BUILD_URL(`report`, {
      base: environment.apiUrl,
      endpoint: 'hcps',
      params: ``,
    });
    const response = this._http.post<TReportHCPResults>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (response) => {
        const hcps: THCP[] = response.hcps.map((result) => {
          return {
            ...result,
          };
        });
        this._HCPs.next(hcps);
        callback(response.paging);
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public deleteHCPs(params: TSetHCPsParams, callback?: () => void): void {
    const { ids, categoryId, projectId } = params;
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Deleting HCPs`);
    this._loadingService.addLoadingItem(loadingItem);
    const idsParam: string = ids.join(SharedConstants.IDS_SPLIT);
    //TODO check if needed
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: `deleteHCPs`,
      params: `?categoryId=${categoryId}&ids=${idsParam}&projectId=${projectId}`,
    });
    this._http.get<THCP[]>(url).subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (results) => {
        if (callback !== undefined) {
          callback();
        }
        console.log(results);
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public importEngagement(
    e: Event,
    projectId: string,
    validate: `true` | `false`,
    callback?: (errors: string[]) => void
  ): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const loadingItem: TLoadingItem =
      this._loadingService.buildLoadingItem(`Importing engagement`);
    this._loadingService.addLoadingItem(loadingItem);
    const files: FileList | null = target.files;
    const url: string = Endpoints.BUILD_URL('import', {
      base: environment.apiUrl,
      endpoint: 'engagement',
      params: ``,
    });
    const formData = new FormData();
    formData.append('file', files![0]);
    formData.append(`projectId`, projectId);
    formData.append(`validate`, validate);
    const response = this._http.post<string[]>(url, formData);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        if (!isNullOrUndefined(callback)) {
          callback(res);
        }
        if (res.length === 0) {
          this._categoriesService.setCategories();
          this._loadingService.triggerOkMessage(
            `Importing engagement finished`
          );
        }
        this._loadingService.removeItem(loadingItem);
      },
    });
  }

  public getCTForProject(
    projectId: string,
    recalculate: boolean = false
  ): Observable<{ studies: string[] }> {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getCTForProject',
      params: `?projectId=${projectId}`,
    });
    const dataFromCache: TCTCacheItem | undefined =
      this._cacheService.getCTItem(projectId);
    if (isNullOrUndefined(dataFromCache) || recalculate) {
      return this._http
        .get<{ studies: string[] }>(url)
        .pipe(tap((data) => data));
    } else {
      const observable: Observable<{ studies: string[] }> = new Observable(
        (subscriber) => {
          subscriber.next({ studies: dataFromCache.ids });
        }
      );
      return observable;
    }
  }

  public handleNextHCP(
    hcpId: string,
    projectId: string,
    callback: (hcpId: string) => void
  ): void {
    const projectRegions = this.buildProjectRegions(projectId);

    const currentPaging: TPagingItem =
      this._pagingService.getPagingItem(projectId);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'searchHcps',
      params: ``,
    });
    const regions: string[] = this._regionsService
      .getSelectedCountries()
      .map((r) => r.value);
    const { showAllCountries, countries } = this.buildCountries(regions);
    const params: TSearchParams<THCP> = {
      area: SearchArea.HCP,
      paging: currentPaging.hcpsPaging,
      projectId,
      query: currentPaging.hcpsPaging.query,
      results: [],
      countries,
      showAllCountries,
      select: { id: 1 },
      projectRegions,
    };

    const response = this._http.post<TSearchParams<THCP>>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        const currentHCPIndex: number = res.results.findIndex(
          (it) => it.id === hcpId
        );
        const nextHCP: THCP | undefined = res.results[currentHCPIndex + 1];
        if (isNullOrUndefined(nextHCP)) {
          const currentHCPTotalIndex =
            params.paging.pageIndex * params.paging.pageSize +
            currentHCPIndex +
            1;
          if (currentHCPTotalIndex >= params.paging.total) {
            this._loadingService.triggerInfoMessage(`No more HCPs`, 1000);
          } else {
            params.paging.pageIndex += 1;
            this.searchAndSetHCPs(params, (newRes) => {
              const currentHCPIndex: number = newRes.results.findIndex(
                (it) => it.id === hcpId
              );
              const newPageNextHCP: THCP | undefined =
                newRes.results[currentHCPIndex + 1];
              if (isNullOrUndefined(newPageNextHCP)) {
                this._loadingService.triggerInfoMessage(`HCP not found`, 1000);
              } else {
                callback(newPageNextHCP.id);
              }
            });
          }
        } else {
          callback(nextHCP.id);
        }
      },
    });
  }

  public handlePreviousHCP(
    hcpId: string,
    projectId: string,
    callback: (hcpId: string) => void
  ): void {
    const projectRegions = this.buildProjectRegions(projectId);

    const currentPaging: TPagingItem =
      this._pagingService.getPagingItem(projectId);
    const url: string = Endpoints.BUILD_URL('rise', {
      base: environment.apiUrl,
      endpoint: 'searchHcps',
      params: ``,
    });
    const regions: string[] = this._regionsService
      .getSelectedCountries()
      .map((r) => r.value);
    const { showAllCountries, countries } = this.buildCountries(regions);
    const params: TSearchParams<THCP> = {
      area: SearchArea.HCP,
      paging: currentPaging.hcpsPaging,
      projectId,
      query: currentPaging.hcpsPaging.query,
      results: [],
      countries,
      showAllCountries,
      select: { id: 1 },
      projectRegions,
    };

    const response = this._http.post<TSearchParams<THCP>>(url, params);
    response.subscribe({
      error: (err) => {
        console.error(err);
        this._loadingService.onError();
      },
      next: (res) => {
        const currentHCPIndex: number = res.results.findIndex(
          (it) => it.id === hcpId
        );
        const prevHCP: THCP | undefined = res.results[currentHCPIndex - 1];
        if (isNullOrUndefined(prevHCP)) {
          const currentHCPTotalIndex =
            params.paging.pageIndex * params.paging.pageSize +
            currentHCPIndex -
            1;
          if (currentHCPTotalIndex < 0) {
            this._loadingService.triggerInfoMessage(`No more HCPs`, 1000);
          } else {
            params.paging.pageIndex -= 1;
            this.searchAndSetHCPs(params, (newRes) => {
              const newPageNextHCP: THCP | undefined =
                newRes.results[newRes.results.length - 1];
              if (isNullOrUndefined(newPageNextHCP)) {
                this._loadingService.triggerInfoMessage(`HCP not found`, 1000);
              } else {
                callback(newPageNextHCP.id);
              }
            });
          }
        } else {
          callback(prevHCP.id);
        }
      },
    });
  }

  public getHCPsByName(query: string): Observable<THCP[]> {
    const url: string = Endpoints.BUILD_URL(`rise`, {
      base: environment.apiUrl,
      endpoint: 'getHCPsByName',
      params: `?query=${query}`,
    });
    return this._http.get<THCP[]>(url).pipe(tap((data) => data));
  }

  public doesRequirePaperNotification(hcp: THCP): boolean {
    const countriesWithPaperNotification = this._settingsService
      .getCountriesWithPaperNotifications()
      .map((c) => c.toLowerCase());
    return countriesWithPaperNotification.includes(hcp.country.toLowerCase());
  }

  private buildCountries(regions: string[]): {
    showAllCountries: boolean;
    countries: string[];
  } {
    const currentUser: TCurrentUser = this._authService.getCurrentUser();
    const isAdmin = currentUser?.level === UserLevel.Admin;
    const showAllCountries =
      isAdmin || (currentUser?.countries || []).length === 0;

    return {
      showAllCountries: showAllCountries,
      countries: regions.includes(WORLD_REGION.value) ? [] : regions,
    };
  }

  private buildProjectRegions(projectId: string) {
    let projectRegions = {
      filter: false,
      filterBy: [] as string[],
    };
    const currentUser: TCurrentUser = this._authService.getCurrentUser();
    const userRegions =
      currentUser?.projectsRegions
        ?.find((pr) => pr.projectId === projectId)
        ?.regions.map((reg) => reg.id) ?? [];

    projectRegions = {
      filter: currentUser?.level !== UserLevel.Admin && userRegions.length > 0,
      filterBy: userRegions,
    };

    return projectRegions;
  }
}
