<mat-label>Project Regions</mat-label>
<mat-select
  class="select-regions"
  multiple
  (selectionChange)="handleSelection($event)"
  [(ngModel)]="selectedRegions"
>
  <mat-option value="ALL">Select all</mat-option>
  <mat-option *ngFor="let region of options" [value]="region.id">
    {{ region.name }}
  </mat-option>
</mat-select>
