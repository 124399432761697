import { Component, Input, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { HCP_DB_MAPPINGS } from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { SearchArea } from '@shared/enums/SearchArea';
import { TCenter, buildCenterMock } from '@shared/interfaces/TCenter';
import { THCP } from '@shared/interfaces/THCP';
import { ROUTES_PATHS } from 'src/app/app-routing.module';
import {
  ICommonTableParams,
  PaginationVisibility,
  SortType,
  THCPTableRow,
  TTDataType,
  TTableRowType,
  TableRowType,
} from 'src/app/common-table/common-table.component.types';
import { TableDataExtender } from 'src/app/common-table/utils/TableDataExtender';
import {
  TCenterParams,
  TComponentType,
  THCPParams,
} from 'src/app/interfaces/TComponentParams';
import { HCPService } from 'src/app/services/HCPService';
import { TDataColumnDef, buildColumnsDef } from 'src/utils/buildColumnsDef';

@Component({
  selector: 'app-hcps-table',
  templateUrl: './hcps-table.component.html',
  styleUrls: ['./hcps-table.component.scss'],
})
export class HcpsTableComponent {
  @Input() public centerParams: TCenterParams = {
    __type: TComponentType.center,
    categoryId: ``,
    centerId: ``,
    projectId: ``,
  };
  @Input() public centerData: TCenter = {
    ...buildCenterMock({}),
  };

  public hcps: THCP[] = [];
  public hcpsCount: number = 0;
  public dataSource: MatTableDataSource<THCPTableRow>;
  public columns: TDataColumnDef<THCPTableRow>[] = [
    ...buildColumnsDef({
      textColumns: [],
      fieldsMappings: Array.from(HCP_DB_MAPPINGS.values()).filter(
        (f) => f.visibleInShortSummary
      ),
    }),
  ];
  public params: ICommonTableParams<TTableRowType, TTDataType>;

  public pageSize: number = 10;
  public pageSizeOptions: number[] = [10, 25, 50, 100, 1000];
  public pageIndex: number = 0;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _HCPsService: HCPService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['centerData']) {
      if (this.isCenterSelected()) {
        this._HCPsService.getHCPsForCenter(
          this.centerData.c_id,
          this.centerParams.projectId,
          (results) => {
            this.hcps = results;
            this.hcpsCount = results.length;
            this.dataSource.data = this.hcps.map((hcp, index) => {
              const extendedHCPprops: any =
                TableDataExtender.HCPTableDataExtender(
                  hcp,
                  this.centerParams.projectId
                );
              return {
                ...extendedHCPprops,
                ...hcp,
                position: index,
                __type: TableRowType.HCP,
              };
            });

            this.params = this.getHcpTableParams();
          }
        );
      } else {
        this.hcps = [];
        this.hcpsCount = 0;
        this.dataSource.data = [];
        this.params = this.getHcpTableParams();
      }
    }
  }

  public getHcpTableParams(): ICommonTableParams<TTableRowType, TTDataType> {
    return {
      columns: this.columns as TDataColumnDef<TTDataType>[],
      dataCount: this.hcpsCount,
      dataLoaded: true,
      dataSource: this.dataSource as MatTableDataSource<TTableRowType>,
      displayedColumns: Array.from(HCP_DB_MAPPINGS.values())
        .filter((f) => f.visibleInShortSummary)
        .map((f) => f.fieldId),
      handleEditClick: (row: TTableRowType) =>
        this._handleEditClick(row as THCPTableRow),
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      pageSizeOptions: this.pageSizeOptions,
      paginationCallback: (e) => this.paginationCallback(e),
      downloadCallback: undefined,
      paginationVisibility: PaginationVisibility.Simple,
      importCallback: void 0,
      area: SearchArea.HCP,
      projectId: this.centerParams.projectId,
      selection: false,
      actionsAvailable: false,
      sortType: SortType.Simple,
      resize: true,
      simpleSortHeaders: [
        `First name`,
        `Last name`,
        `City`,
        `Country`,
        `Influence score`,
        `Engagement score`,
        `Value score`,
        `History publications count`,
        `Publications total`,
      ],
      defaultSortFieldId: `influenceScore`,
    };
  }

  public async paginationCallback(e: PageEvent): Promise<void> {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
  }

  private _handleEditClick(row: THCPTableRow): void {
    const hcpParams: THCPParams = {
      hcpId: row.id,
      categoryId: this.centerParams.categoryId ?? ``,
      projectId: this.centerParams.projectId ?? ``,
      __type: TComponentType.hcp,
    };
    this._router.navigate([`/${ROUTES_PATHS.hcp}`, hcpParams], {
      relativeTo: this._route,
    });
  }

  private isCenterSelected(): boolean {
    return this.centerData.c_id !== `` && this.centerParams.centerId !== ``;
  }
}
