import { CustomRegion } from '@shared/interfaces/TSettings';
import { TRegion, WORLD_REGION } from '../services/RegionsService';

export const handleSelection = (
  region: TRegion,
  selectedValues: TRegion[],
  customRegions: CustomRegion[] = [],
  allowWorld: boolean = true
): TRegion[] => {
  // handle World region selection
  if (allowWorld) {
    if (region.value === WORLD_REGION.value || selectedValues.length === 0) {
      selectedValues = [WORLD_REGION];
      return selectedValues;
    }
  } else {
    if (selectedValues.length === 0) {
      selectedValues = [];
      return selectedValues;
    }
  }
  // filter out world region
  selectedValues = selectedValues.filter((v) => v.value !== WORLD_REGION.value);
  //   if region is unselected
  if (!selectedValues.map((v) => v.value).includes(region.value)) {
    // if unselected is a custom region, remove  its countries
    const customRegion = customRegions.find((r) => r.name === region.value);
    if (customRegion) {
      const countries = customRegion.countries.map((c) => c.value);
      selectedValues = selectedValues.filter(
        (v) => !countries.includes(v.value)
      );
    } else {
      // if unselected is a country, remove its custom regions
      const customRegionsForCountry = customRegions.filter((r) =>
        r.countries.some((c) => c.value === region.value)
      );
      customRegionsForCountry.forEach((customRegion) => {
        selectedValues = selectedValues.filter(
          (v) => v.value !== customRegion.name
        );
      });
    }
  }
  // add countries for selected custom regions
  let countries: TRegion[] = [];
  const selectedValuesNames = selectedValues.map((v) => v.value);
  customRegions.forEach((customRegion) => {
    if (selectedValuesNames.includes(customRegion.name)) {
      countries = [...countries, ...customRegion.countries];
    }
  });
  selectedValues = [...selectedValues, ...countries];

  if (selectedValues.length === 0 && allowWorld) {
    selectedValues = [WORLD_REGION];
  }
  if (selectedValues.length === 0 && !allowWorld) {
    selectedValues = [];
  }

  return selectedValues;
};
