import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CENTER_DB_MAPPINGS } from '@shared/db-fields-mappings/CENTER_DB_MAPPINGS';
import {
  HCP_DB_MAPPINGS,
  influenceOptions,
} from '@shared/db-fields-mappings/HCP_DB_MAPPINGS';
import { SearchArea } from '@shared/enums/SearchArea';
import { TCenter } from '@shared/interfaces/TCenter';
import { TDBFieldMappingItem } from '@shared/interfaces/TDBFieldMappingItem';
import { THCP } from '@shared/interfaces/THCP';
import {
  FilterOperator,
  NoScoreReport,
  TNoScoreReport,
  TProject,
  TReportFilterProperty,
  TReportType,
  TTagItem,
  TTierItem,
  filterOperatorsMap,
} from '@shared/interfaces/TProject';
import { TReportQuery } from '@shared/interfaces/TReportQuery';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { newGuid } from '@shared/utils/newGuild';
import { Subscription } from 'rxjs';
import { TDataColumnDef, buildColumnsDef } from 'src/utils/buildColumnsDef';
import { getFilteringLegend } from '../../utils/filter';
import { ROUTES_PATHS } from '../app-routing.module';
import {
  ICommonTableParams,
  PaginationVisibility,
  SortType,
  TCenterTableRow,
  THCPTableRow,
  TTDataType,
  TTableRowType,
  TableRowType,
} from '../common-table/common-table.component.types';
import { TableDataExtender } from '../common-table/utils/TableDataExtender';
import {
  TCenterParams,
  TComponentParent,
  TComponentType,
  THCPParams,
} from '../interfaces/TComponentParams';
import { TProjectReviewParams } from '../project-review/project-review.component';
import { CacheService, TCacheReportParams } from '../services/CacheService';
import { CategoriesService } from '../services/CategoriesService';
import { CentersService } from '../services/CentersService';
import { HCPService } from '../services/HCPService';
import { LoadingService } from '../services/LoadingService';
import {
  RegionsService,
  TRegion,
  WORLD_REGION,
} from '../services/RegionsService';
import { SettingsService } from '../services/SettingsService';
import { AuthService } from '../services/auth/auth.service';
import { PagingService } from '../services/paging/PagingService';
import { TPagingItem } from '../services/paging/TPagingItem';
import {
  ReportCustomMetricComponent,
  TReportCustomMetricsParams,
} from './report-custom-metric/report-custom-metric.component';
import {
  FilterLegend,
  ReportFiltersComponent,
  TReportDataFilter,
  TReportFiltersParams,
} from './report-filters/report-filters.component';
import { TReportCell, TReportComponentParams } from './report/report.component';

export enum ReportType {
  HCP = 'HCP',
  Center = 'CENTER',
}

export interface TReportParams {
  data: string;
  loadFromCache?: boolean;
  projectId: string;
}

export interface TScoreButtonItem {
  name: string;
  value: TReportType;
  callback: (button: TScoreButtonItem) => void;
  selected: boolean;
}

function visibleHCPColumnsFilter(f: TDBFieldMappingItem): boolean {
  return f.visibleInRaport === true;
}

function visibleCenterColumnsFilter(
  f: TDBFieldMappingItem,
  usOnly: boolean = false
): boolean {
  if (usOnly) {
    return f.visibleInRaport === true || f.usOnly === true;
  }
  return f.visibleInRaport === true;
}

@Component({
  selector: 'app-reports-review',
  templateUrl: './reports-review.component.html',
  styleUrls: ['./reports-review.component.scss'],
})
export class ReportsReviewComponent implements OnInit, OnDestroy {
  public selectedRegions = new FormControl<TRegion[]>([]);
  public regionsFiltered: TRegion[] = [];
  public selectedRegionsSummary: string = ``;
  public regions: TRegion[] = [];
  public tags: FormControl<TTagItem[] | null> = new FormControl<
    TTagItem[] | null
  >(null);
  public availableTags: TTagItem[] = [];
  public projectCustomMetrics: TTagItem[] = [];
  public scoresButtons: TScoreButtonItem[] = [];
  public reportComponentParams: TReportComponentParams = {
    matrix: [],
    xAxisLabel: `Influence`,
    yAxisLabel: `Engagement`,
    xAxisType: 'hcpInfluence',
    yAxisType: 'hcpEngagement',
    projectId: ``,
  };
  public hcpTableParams: ICommonTableParams<TTableRowType, TTDataType>;
  public centerTableParams: ICommonTableParams<TTableRowType, TTDataType>;

  public reportParams: TReportParams = {
    data: ``,
    projectId: ``,
  };
  public hcpColumns: TDataColumnDef<THCP>[] = [
    ...buildColumnsDef({
      textColumns: [],
      fieldsMappings: Array.from(HCP_DB_MAPPINGS.values()).filter(
        visibleHCPColumnsFilter
      ),
    }),
  ];
  public centerColumns: TDataColumnDef<TCenter>[] = [
    ...buildColumnsDef({
      textColumns: [],
      fieldsMappings: Array.from(CENTER_DB_MAPPINGS.values()).filter((f) =>
        visibleCenterColumnsFilter(f, true)
      ),
    }),
  ];
  public selectedReport: TReportType | NoScoreReport | null = null;
  public hcpsCount: number = 0;
  public hcps: THCP[] = [];
  public centers: TCenter[] = [];
  public hcpsDataSource: MatTableDataSource<THCPTableRow>;
  public centersDataSource: MatTableDataSource<TCenterTableRow>;
  public usOnly: boolean = false;
  public reportFilters: TReportDataFilter[] = [];
  private _subs: Subscription[] = [];
  private params: TReportQuery | null = null;

  public noScoreReport: TNoScoreReport = {
    type: 'basicHcp',
    selected: false,
  };

  constructor(
    public filterDialog: MatDialog,
    public metricDialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _regionsService: RegionsService,
    private _categoriesService: CategoriesService,
    private _loadingService: LoadingService,
    private _pagingService: PagingService,
    private _HCPsService: HCPService,
    private _authService: AuthService,
    private _centerService: CentersService,
    private _settings: SettingsService,
    private _cacheService: CacheService
  ) {
    this.hcpsDataSource = new MatTableDataSource();
    this.centersDataSource = new MatTableDataSource();
  }

  public setRegionFromCache(): void {
    const reportCacheParams: TCacheReportParams | undefined =
      this._cacheService.getSelectedReport();
    let regionsFromCache: TRegion[] | undefined =
      this._regionsService.getAllCountries();
    if (!isNullOrUndefined(reportCacheParams)) {
      regionsFromCache = regionsFromCache.filter((c) =>
        reportCacheParams.selectedRegions
          ?.map((it) => it.value)
          .includes(c.value)
      );
      this.selectedRegions.setValue(regionsFromCache);
    }
  }

  public openFiltersDialog(): void {
    const legend: FilterLegend[] = [];
    if (this.reportFilters.length > 0) {
      this.reportFilters.forEach((filter) => {
        const isLegendExisting = !!legend.find(
          (l) => l.property === filter.property
        );
        if (!isLegendExisting) {
          const item = getFilteringLegend(
            filter.property as TReportFilterProperty
          );
          item && legend.push(item);
        }
      });
    }
    this.filterDialog.open<ReportFiltersComponent, TReportFiltersParams>(
      ReportFiltersComponent,
      {
        data: {
          selectedReport: this.selectedReport,
          filters: this.reportFilters,
          availableTags: this.availableTags,
          projectId: this.reportParams.projectId,
          filtersLegend: legend,
        },
      }
    );
  }

  public deleteFilter(item: TReportDataFilter): void {
    const itemIndex: number = this.reportFilters.findIndex(
      (it) => it.property === item.property
    );
    if (itemIndex > -1) {
      this.reportFilters.splice(itemIndex, 1);
    }
  }

  public openCustomMetricsDialog(): void {
    this.metricDialog.open<
      ReportCustomMetricComponent,
      TReportCustomMetricsParams
    >(ReportCustomMetricComponent, {
      data: {
        selectedReport: this.selectedReport,
        filters: this.reportFilters,
        projectCustomMetrics: this.projectCustomMetrics,
        projectId: this.reportParams.projectId,
      },
    });
  }

  public buildFilterReview(filterItem: TReportDataFilter): string {
    let mappings: Map<string, TDBFieldMappingItem> = HCP_DB_MAPPINGS;
    if (this.selectedReport === 'Center') {
      mappings = CENTER_DB_MAPPINGS;
    }
    if (this.selectedReport === 'basicCenter') {
      mappings = CENTER_DB_MAPPINGS;
    }
    if (this.selectedReport === 'metrics') {
      return this._metricFilterReview(filterItem);
    }
    if (this.selectedReport === 'basicCenter') {
      if (filterItem.metric) {
        return this._metricFilterReview(filterItem);
      }
      return this._handleFilterFieldReview(mappings, filterItem);
    }

    if (filterItem.type === 'engagement') {
      const date = new Date(filterItem.date ?? ``);
      return `Field ${
        mappings.get(filterItem.property)?.name
      } ${filterOperatorsMap.get(
        filterItem.operator
      )} ${date.toLocaleDateString(
        this._settings.getAppSettings().locale
      )} tags: ${(filterItem.tags ?? []).map((el) => el.tag).join(`, `)}`;
    }
    if (filterItem.property === `kols_status`) {
      const results =
        influenceOptions.find((it) => it.value === filterItem.value)?.key ??
        `N/A`;
      return `Field ${
        mappings.get(filterItem.property)?.name
      } ${filterOperatorsMap.get(filterItem.operator)} ${results}`;
    }
    if (
      filterItem.type === `regular` &&
      mappings.get(filterItem.property)?.isTagField === true
    ) {
      return `Field ${
        mappings.get(filterItem.property)?.name
      } ${filterOperatorsMap.get(filterItem.operator)} ${(filterItem.tags ?? [])
        .map((it) => it.name)
        .join(`, `)}`;
    }
    return this._handleFilterFieldReview(mappings, filterItem);
  }

  private _metricFilterReview(filterItem: TReportDataFilter): string {
    const metric =
      filterItem.metric === 'value' ? 'quantity' : filterItem.metric;
    return `Metric ${filterItem.property}: ${metric} ${filterOperatorsMap.get(
      filterItem.operator
    )} ${filterItem.value}`;
  }

  private _handleFilterFieldReview(
    mappings: Map<string, TDBFieldMappingItem>,
    filterItem: TReportDataFilter
  ): string {
    return `Field ${
      mappings.get(filterItem.property)?.name
    } ${filterOperatorsMap.get(filterItem.operator)} ${filterItem.value}`;
  }

  public getButtonCSS(selected: boolean): string {
    const classes: string[] = [`choose-report-button`];
    if (selected) {
      classes.push(`choose-report-button--selected`);
    }
    return classes.join(` `);
  }

  public selectNoScoreReport(type: NoScoreReport): void {
    this._resetButton();
    this.noScoreReport.selected = true;
    this.noScoreReport.type = type;
    this.selectedReport = type;
    if (type === 'basicHcp') {
      if (
        this.reportFilters.find((it) => it.property === 'engagementTags') ===
        undefined
      ) {
        this.reportFilters = [
          {
            operator: FilterOperator.Equal,
            property: 'engagementTags',
            value: '',
            id: newGuid(),
            type: 'engagement',
            date: new Date(),
            tags: [],
          },
        ];
      }
      this.openFiltersDialog();
    }
    if (type === 'basicCenter') {
      this.reportFilters = [];
    }
    if (type === 'compliance1') {
      this.reportFilters = [
        {
          id: newGuid(),
          operator: FilterOperator.Equal,
          property: `has_priority`,
          type: 'regular',
          value: true,
        },
        {
          id: newGuid(),
          operator: FilterOperator.Equal,
          property: `opt_out_information_status`,
          type: 'regular',
          value: 0,
        },
      ];
    }
    if (type === 'metrics') {
      this.reportFilters = [];
    }
  }

  public generateReport(): void {
    if (this.noScoreReport.selected) {
      this.getNoScoreReport();
    } else {
      this.getSelectedReport(false);
    }
  }

  private getNoScoreReport(): void {
    const errors: string[] = [];
    this.reportParams.loadFromCache = false;
    if (this.selectedReport === null) {
      errors.push(`report`);
    }
    if (this.selectedRegions.value?.length === 0) {
      errors.push(`region`);
    }
    if (errors.length > 0) {
      this._loadingService.triggerErrorMessage(
        `Please select: ${errors.join(`, `)}`,
        5000
      );
    } else {
      const currentPaging: TPagingItem = this._pagingService.getPagingItem(
        this.reportParams.projectId
      );

      const query = (): string => {
        if (this.selectedReport === 'compliance1') {
          return '{"priority_timestamp":{"$exists":true,"$not":{"$size":0}}}';
        }
        return ``;
      };

      this.params = {
        projectId: this.reportParams.projectId,
        regions:
          this.selectedRegions.value
            ?.map((it) => it.value)
            .filter((item) => item !== WORLD_REGION.value) ?? [],
        paging: currentPaging.hcpsReportPaging,
        query: query(),
        tags: this.tags.value ?? [],
        filters: this.reportFilters,
      };
      this._setTableData(false);
    }
  }

  private getSelectedReport(updateRegions: boolean): void {
    const errors: string[] = [];
    this.reportParams.loadFromCache = false;
    if (this.selectedReport === null) {
      errors.push(`report`);
    }
    if (this.selectedRegions.value?.length === 0) {
      errors.push(`region`);
    }
    if (
      this.reportComponentParams.matrix.length === 0 ||
      this.reportComponentParams.matrix.every((row) =>
        row.every((cell) => cell.isSelected === false)
      )
    ) {
      errors.push(`group`);
    }
    if (errors.length > 0) {
      this._loadingService.triggerErrorMessage(
        `Please select: ${errors.join(`, `)}`,
        5000
      );
    } else {
      const currentPaging: TPagingItem = this._pagingService.getPagingItem(
        this.reportParams.projectId
      );
      this.params = {
        projectId: this.reportParams.projectId,
        regions:
          this.selectedRegions.value
            ?.map((it) => it.value)
            .filter((item) => item !== WORLD_REGION.value) ?? [],
        types: {
          x: this.reportComponentParams.xAxisType,
          y: this.reportComponentParams.yAxisType,
        },
        matrix: this.reportComponentParams.matrix
          .flat()
          .filter((r) => r.isSelected),
        paging:
          this.reportComponentParams.xAxisType === 'center'
            ? currentPaging.centersPaging
            : currentPaging.hcpsReportPaging,
        query: ``,
        tags: this.tags.value ?? [],
        filters: this.reportFilters,
      };
      this._setTableData(updateRegions);
    }
  }

  public ngOnInit(): void {
    this._subs.push(
      this._route.params.subscribe(async (params: any) => {
        this.reportParams = {
          data: params.data as string,
          projectId: params.projectId as string,
          loadFromCache: params?.loadFromCache === `true` ? true : false,
        };
        this.setReport();
      })
    );
    this._subs.push(
      this._regionsService.getCountriesObservable().subscribe((regions) => {
        this.regions = regions;
      })
    );
    this._subs.push(
      this._HCPsService
        .getExtendedHCPsObservable()
        .subscribe((hcps: THCP[]) => {
          this.hcps = hcps;
          this._processHCPsTableData();
        })
    );
    this._subs.push(
      this._centerService
        .getCentersObservable()
        .subscribe((centers: TCenter[]) => {
          this.centers = centers;
          this._processCentersTableData();
        })
    );
    this.hcps = [];
    this.centers = [];
    this._subs.push(
      this._authService.getCurrentUserObservable().subscribe((user) => {
        this.selectedRegions.patchValue(
          this._regionsService.getSelectedCountries()
        );
      })
    );
    this._subs.push(
      this._regionsService.getCountriesObservable().subscribe((regions) => {
        this.regions = regions;
        this.regionsFiltered = regions;
      })
    );
    this._subs.push(
      this._categoriesService.getCategoriesObservable().subscribe(() => {
        this._setTags();
      })
    );
  }

  public isReportButtonDisabled(btn: TScoreButtonItem): boolean {
    return this.getButtonError(btn).length > 0;
  }

  public isMatrixReport(): boolean {
    return [
      'ValueInfluence',
      'ValueEngagement',
      'InfluenceEngagement',
      'ProductUseValue',
      'ProductUseInfluence',
      'ProductUseEngagement',
      'Center',
    ].includes(this.selectedReport as string);
  }

  public getButtonError(btn: TScoreButtonItem): string {
    const project: TProject | undefined = this._categoriesService.getProject(
      this.reportParams.projectId
    );
    if (project !== undefined) {
      const errors: string[] = [];
      const baseErrorMessage: string = `Please set tiers for HCP `;
      switch (btn.value) {
        case `Center`:
          if ((project.tiersDefinitions.center ?? []).length === 0) {
            errors.push(`Please set tiers for center value`);
          }
          return errors.join(` and `);
        case `InfluenceEngagement`:
          if (project.tiersDefinitions.hcpInfluence.length === 0) {
            errors.push(`${baseErrorMessage}influence.`);
          }
          if (project.tiersDefinitions.hcpEngagement.length === 0) {
            errors.push(`${baseErrorMessage}engagement.`);
          }
          return errors.join(` and `);
        case `RisingStar`:
          if (project.tiersDefinitions?.risingStar?.length === 0) {
            errors.push(`${baseErrorMessage}rising star.`);
          }
          return errors.join(` and `);
        case `ValueEngagement`:
          if (project.tiersDefinitions.hcpValue.length === 0) {
            errors.push(`${baseErrorMessage}value.`);
          }
          if (project.tiersDefinitions.hcpEngagement.length === 0) {
            errors.push(`${baseErrorMessage}engagement.`);
          }
          return errors.join(` and `);
        case `ValueInfluence`:
          if (project.tiersDefinitions.hcpValue.length === 0) {
            errors.push(`${baseErrorMessage}value.`);
          }
          if (project.tiersDefinitions.hcpInfluence.length === 0) {
            errors.push(`${baseErrorMessage}influence.`);
          }
          return errors.join(` and `);
        case `ProductUseValue`:
          if ((project.tiersDefinitions.hcpProductUse ?? []).length === 0) {
            errors.push(`${baseErrorMessage}product use.`);
          }
          if (project.tiersDefinitions.hcpValue.length === 0) {
            errors.push(`${baseErrorMessage}value.`);
          }
          return errors.join(` and `);
        case `ProductUseInfluence`:
          if ((project.tiersDefinitions.hcpProductUse ?? []).length === 0) {
            errors.push(`${baseErrorMessage}product use.`);
          }
          if (project.tiersDefinitions.hcpInfluence.length === 0) {
            errors.push(`${baseErrorMessage}influence.`);
          }
          return errors.join(` and `);
        case `ProductUseEngagement`:
          if ((project.tiersDefinitions.hcpProductUse ?? []).length === 0) {
            errors.push(`${baseErrorMessage}product use.`);
          }
          if (project.tiersDefinitions.hcpInfluence.length === 0) {
            errors.push(`${baseErrorMessage}engagement.`);
          }
          return errors.join(` and `);
      }
    }
    return ``;
  }

  public handleRegionChange(): void {
    this.selectedRegionsSummary =
      this.selectedRegions.value?.map((v) => v.viewValue).join(`, `) ?? ``;
  }

  public ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  public closeReport(): void {
    const projectReviewParams: TProjectReviewParams = {
      projectId: this.reportParams.projectId,
      categoryId:
        this._categoriesService.getCategoryFromProjectId(
          this.reportParams.projectId
        )?.categoryId ?? ``,
    };
    this._router.navigate([`/${ROUTES_PATHS.project}`, projectReviewParams]);
  }

  private setReport(): void {
    this.scoresButtons = this.buildScoresButtons();
    if (this.reportParams.loadFromCache === true) {
      const reportCacheParams: TCacheReportParams | undefined =
        this._cacheService.getSelectedReport();
      if (!isNullOrUndefined(reportCacheParams)) {
        const regions: TRegion[] | undefined = this._regionsService
          .getAllCountries()
          .filter((c) =>
            reportCacheParams.selectedRegions
              ?.map((it) => it.value)
              .includes(c.value)
          );
        this.selectedRegions.setValue(regions);
        this.selectedReport = reportCacheParams.type;
        this.reportComponentParams = reportCacheParams.reportComponentParams;
        this.reportFilters = reportCacheParams.reportFilters;
        this.tags.setValue(reportCacheParams.tags);
        const scoreButton: TScoreButtonItem | undefined =
          this.scoresButtons.find((it) => it.value === reportCacheParams.type);
        if (!isNullOrUndefined(scoreButton)) {
          scoreButton.selected = true;
        }

        this.getSelectedReport(true);
      }
    }
  }

  private buildScoresButtons(): TScoreButtonItem[] {
    return [
      {
        callback: (button: TScoreButtonItem) =>
          this.handleScoreClick(`ValueInfluence`, button),
        name: `HCP Value - Influence`,
        value: `ValueInfluence`,
        selected: false,
      },
      {
        callback: (button: TScoreButtonItem) =>
          this.handleScoreClick(`ValueEngagement`, button),
        name: `HCP Value - Engagement`,
        value: `ValueEngagement`,
        selected: false,
      },
      {
        callback: (button: TScoreButtonItem) =>
          this.handleScoreClick(`InfluenceEngagement`, button),
        name: `HCP Influence - Engagement`,
        value: `InfluenceEngagement`,
        selected: false,
      },
      {
        callback: (button: TScoreButtonItem) =>
          this.handleScoreClick(`ProductUseValue`, button),
        name: `HCP Product Use - Value`,
        value: `ProductUseValue`,
        selected: false,
      },
      {
        callback: (button: TScoreButtonItem) =>
          this.handleScoreClick(`ProductUseInfluence`, button),
        name: `HCP Product Use - Influence`,
        value: `ProductUseInfluence`,
        selected: false,
      },
      {
        callback: (button: TScoreButtonItem) =>
          this.handleScoreClick(`ProductUseEngagement`, button),
        name: `HCP Product Use - Engagement`,
        value: `ProductUseEngagement`,
        selected: false,
      },
      {
        callback: (button: TScoreButtonItem) =>
          this.handleScoreClick(`RisingStar`, button),
        name: `HCP Rising Star`,
        value: `RisingStar`,
        selected: false,
      },
      {
        callback: (button: TScoreButtonItem) =>
          this.handleScoreClick(`Center`, button),
        value: `Center`,
        name: `Center score`,
        selected: false,
      },
      {
        callback: (button: TScoreButtonItem) =>
          this.handleScoreClick(`Center`, button, true),
        value: `Center`,
        name: `Center score US`,
        selected: false,
      },
    ];
  }

  private _resetButton(): void {
    this.scoresButtons.forEach((b) => (b.selected = false));
    this.noScoreReport.selected = false;
    this.reportFilters = [];
  }

  private handleScoreClick(
    type: TReportType,
    button: TScoreButtonItem,
    usOnly: boolean = false
  ): void {
    this._resetButton();
    button.selected = true;
    this.selectedReport = type;
    this.buildMatrix();

    this.hcps = [];
    this.centers = [];
    if (usOnly) {
      const usRegion: TRegion | undefined = this._regionsService
        .getAllCountries()
        .find((c) => c.value === `US`);
      if (!isNullOrUndefined(usRegion)) {
        this.selectedRegions.setValue([usRegion]);
      }
    } else {
      this.selectedRegions.setValue([WORLD_REGION]);
    }
    if (type === 'RisingStar') {
      this.reportFilters = [
        {
          id: newGuid(),
          operator: FilterOperator.Equal,
          property: `retired`,
          type: 'regular',
          value: false,
        },
        {
          id: newGuid(),
          operator: FilterOperator.NotEqual,
          property: `kols_status`,
          type: 'regular',
          value: 1,
        },
        {
          id: newGuid(),
          operator: FilterOperator.NotEqual,
          property: `kols_status`,
          type: 'regular',
          value: 2,
        },
      ];
    }
    this.handleRegionChange();
    this.usOnly = usOnly;
    if (usOnly === true) {
      this.selectedRegions.disable();
    } else {
      this.selectedRegions.enable();
    }
  }

  private _getTiers(project: TProject): { x: TTierItem[]; y: TTierItem[] } {
    switch (this.selectedReport) {
      case `ValueInfluence`:
        this.reportComponentParams.xAxisLabel = `Value`;
        this.reportComponentParams.xAxisType = `hcpValue`;
        this.reportComponentParams.yAxisLabel = `Influence`;
        this.reportComponentParams.yAxisType = `hcpInfluence`;
        return {
          x: [...project.tiersDefinitions.hcpValue].reverse(),
          y: project.tiersDefinitions.hcpInfluence,
        };
      case `ValueEngagement`:
        this.reportComponentParams.xAxisLabel = `Value`;
        this.reportComponentParams.xAxisType = `hcpValue`;
        this.reportComponentParams.yAxisType = `hcpEngagement`;
        this.reportComponentParams.yAxisLabel = `Engagement`;

        return {
          x: [...project.tiersDefinitions.hcpValue].reverse(),
          y: project.tiersDefinitions.hcpEngagement,
        };
      case `InfluenceEngagement`:
        this.reportComponentParams.xAxisLabel = `Influence`;
        this.reportComponentParams.xAxisType = `hcpInfluence`;
        this.reportComponentParams.yAxisLabel = `Engagement`;
        this.reportComponentParams.yAxisType = `hcpEngagement`;
        return {
          x: [...project.tiersDefinitions.hcpInfluence].reverse(),
          y: project.tiersDefinitions.hcpEngagement,
        };
      case `ProductUseValue`:
        this.reportComponentParams.xAxisLabel = `Product use`;
        this.reportComponentParams.xAxisType = `hcpProductUse`;
        this.reportComponentParams.yAxisLabel = `Value`;
        this.reportComponentParams.yAxisType = `hcpValue`;
        return {
          x: [...project.tiersDefinitions.hcpProductUse].reverse(),
          y: project.tiersDefinitions.hcpValue,
        };
      case `ProductUseInfluence`:
        this.reportComponentParams.xAxisLabel = `Product use`;
        this.reportComponentParams.xAxisType = `hcpProductUse`;
        this.reportComponentParams.yAxisLabel = `Influence`;
        this.reportComponentParams.yAxisType = `hcpInfluence`;
        return {
          x: [...project.tiersDefinitions.hcpProductUse].reverse(),
          y: project.tiersDefinitions.hcpInfluence,
        };
      case `ProductUseEngagement`:
        this.reportComponentParams.xAxisLabel = `Product use`;
        this.reportComponentParams.xAxisType = `hcpProductUse`;
        this.reportComponentParams.yAxisLabel = `Engagement`;
        this.reportComponentParams.yAxisType = `hcpEngagement`;
        return {
          x: [...project.tiersDefinitions.hcpProductUse].reverse(),
          y: project.tiersDefinitions.hcpEngagement,
        };

      case `Center`:
        this.reportComponentParams.xAxisLabel = 'Center value';
        this.reportComponentParams.xAxisType = 'center';
        this.reportComponentParams.yAxisLabel = 'N/A';
        this.reportComponentParams.yAxisType = 'center';
        return {
          x: project.tiersDefinitions.center,
          y: [
            {
              high: 0,
              low: 0,
              name: `N/A`,
              position: 0,
              total: 0,
            },
          ],
        };
      case `RisingStar`:
        this.reportComponentParams.xAxisLabel = 'Rising Star';
        this.reportComponentParams.xAxisType = 'risingStar';
        this.reportComponentParams.yAxisLabel = 'N/A';
        this.reportComponentParams.yAxisType = 'risingStar';
        return {
          x: (project.tiersDefinitions.risingStar ?? []).reverse(),
          y: [
            {
              high: 0,
              low: 0,
              name: `N/A`,
              position: 0,
              total: 0,
            },
          ],
        };
    }
    return {
      x: [],
      y: [],
    };
  }

  private buildMatrix(): void {
    const matrix: TReportCell[][] = [];
    const project: TProject | undefined = this._categoriesService.getProject(
      this.reportParams.projectId
    );
    if (!isNullOrUndefined(project)) {
      this.reportComponentParams.projectId = project.id;
      const { x, y } = this._getTiers(project);
      x.forEach((xItem) => {
        const row: TReportCell[] = [];
        y.forEach((yItem) => {
          row.push({
            isSelected: true,
            xValue: xItem.name,
            yValue: yItem.name,
          });
        });
        matrix.push(row);
      });
    }
    this.reportComponentParams.matrix = matrix;
  }

  private _processHCPsTableData(): void {
    this.hcpsDataSource.data = this.hcps.map((hcp, index) => {
      const extendedHCPprops: any = TableDataExtender.HCPTableDataExtender(
        hcp,
        this.reportParams.projectId
      );
      return {
        ...extendedHCPprops,
        ...hcp,
        position: index,
        __type: TableRowType.HCP,
      };
    });
  }

  private _processCentersTableData(): void {
    this.centersDataSource.data = this.centers.map((center, index) => {
      const extendedCenterProps: any =
        TableDataExtender.CenterTableDataExtender(
          center,
          this.reportParams.projectId
        );
      return {
        ...extendedCenterProps,
        ...center,
        position: index,
        __type: TableRowType.Center,
      };
    });
  }

  public getHCPsTableParams(): ICommonTableParams<TTableRowType, TTDataType> {
    const currentPaging: TPagingItem = this._pagingService.getPagingItem(
      this.reportParams.projectId
    );
    const actionsColumn = this.hcpColumns.find(
      (c) => c.columnDef === 'actions'
    );
    if (
      !isNullOrUndefined(actionsColumn) &&
      this.selectedReport === 'RisingStar'
    ) {
      actionsColumn.header = `Rising star`;
    }
    let displayedColumns = Array.from(HCP_DB_MAPPINGS.values())
      .filter(visibleHCPColumnsFilter)
      .map((f) => f.fieldId);
    const columns = this.hcpColumns;

    return {
      columns: columns as TDataColumnDef<TTDataType>[],
      dataCount: currentPaging.hcpsReportPaging.total,
      dataLoaded: true,
      dataSource: this.hcpsDataSource as MatTableDataSource<TTableRowType>,
      displayedColumns: displayedColumns,
      pageIndex: currentPaging.hcpsReportPaging.pageIndex,
      pageSize: currentPaging.hcpsReportPaging.pageSize,
      pageSizeOptions: this._pagingService.pageSizeOptions,
      paginationCallback: (e) => this.paginationCallback(e),
      projectId: this.reportParams.projectId,
      area: SearchArea.HCPsReports,
      resize: true,
      selection: this.selectedReport === 'RisingStar',
      selectionAlwaysVisible: this.selectedReport === 'RisingStar',
      actionsAvailable: this.selectedReport !== 'Center',
      sortType: SortType.Extended,
      downloadCallback: () => this.download(),
      handleHeaderClick: (fieldId, remove) =>
        this._handleHeaderClick(fieldId, remove),
      paginationVisibility: PaginationVisibility.Top,
      hasRisingStarButton: this.selectedReport === 'RisingStar',
      mergeHidden: true,
      handleEditClick: (row: TTableRowType) =>
        this._handleHCPEditClick(<THCPTableRow>row),
    };
  }

  public getCentersTableParams(): ICommonTableParams<
    TTableRowType,
    TTDataType
  > {
    const currentPaging: TPagingItem = this._pagingService.getPagingItem(
      this.reportParams.projectId
    );
    return {
      columns: this.centerColumns as TDataColumnDef<TTDataType>[],
      dataCount: currentPaging.centersReportPaging.total,
      dataLoaded: true,
      dataSource: this.centersDataSource as MatTableDataSource<TTableRowType>,
      displayedColumns: Array.from(CENTER_DB_MAPPINGS.values())
        .filter((f) => visibleCenterColumnsFilter(f, this.usOnly))
        .map((f) => f.fieldId),
      pageIndex: currentPaging.centersReportPaging.pageIndex,
      pageSize: currentPaging.centersReportPaging.pageSize,
      pageSizeOptions: this._pagingService.pageSizeOptions,
      paginationCallback: (e) => this.paginationCallback(e, true),
      projectId: this.reportParams.projectId,
      area: SearchArea.CentersReports,
      resize: true,
      selection: false,
      selectionAlwaysVisible: false,
      actionsAvailable: true,
      sortType: SortType.Extended,
      downloadCallback: () => this.download(true),
      handleHeaderClick: (fieldId, remove) =>
        this._handleHeaderClick(fieldId, remove, true),
      paginationVisibility: PaginationVisibility.Top,
      hasRisingStarButton: false,
      mergeHidden: true,
      handleEditClick: (row: TTableRowType) =>
        this._handleCenterEditClick(<THCPTableRow>row),
    };
  }

  public tagsAvailable(): boolean {
    return (
      this.availableTags.length > 0 &&
      this.reportComponentParams.xAxisType !== `center`
    );
  }

  private download(center: boolean = false): void {
    if (!isNullOrUndefined(this.params)) {
      if (center) {
        this._centerService.downloadCentersFromReport(this.params);
      } else {
        this._HCPsService.downloadHCPFromReport(this.params);
      }
    }
  }

  private _handleHeaderClick(
    fieldId: string,
    remove: boolean,
    center: boolean = false
  ): void {
    if (!isNullOrEmpty(this.reportParams.projectId)) {
      if (center) {
        this._pagingService.handleSort({
          area: SearchArea.CentersReports,
          fieldId: fieldId,
          projectId: this.reportParams.projectId,
          remove: remove,
        });
      } else {
        this._pagingService.handleSort({
          area: SearchArea.HCPsReports,
          fieldId: fieldId,
          projectId: this.reportParams.projectId,
          remove: remove,
        });
      }
      this._setTableData(false);
    }
  }

  public paginationCallback(e: PageEvent, center: boolean = false): void {
    if (!isNullOrEmpty(this.reportParams.projectId)) {
      const currentPaging: TPagingItem = structuredClone(
        this._pagingService.getPagingItem(this.reportParams.projectId)
      );
      if (center) {
        currentPaging.centersReportPaging.pageIndex = e.pageIndex;
        currentPaging.centersReportPaging.pageSize = e.pageSize;
      } else {
        currentPaging.hcpsReportPaging.pageIndex = e.pageIndex;
        currentPaging.hcpsReportPaging.pageSize = e.pageSize;
      }
      this._pagingService.updatePagingItem(
        this.reportParams.projectId,
        currentPaging
      );
      this._setTableData(false);
    }
  }

  public applyFilter(term: string) {
    if (term.length > 3) {
      this.params!.query = term;
      this._setTableData(false);
    } else if (term.length === 0) {
      this.params!.query = ``;
      this._setTableData(false);
    }
  }

  private _setTableData(updateRegions: boolean): void {
    if (!isNullOrEmpty(this.reportParams.projectId)) {
      const currentPaging: TPagingItem = this._pagingService.getPagingItem(
        this.reportParams.projectId
      );

      const isCenterReport: boolean =
        this.reportComponentParams.xAxisType === `center` ||
        this.selectedReport === 'metrics' ||
        this.selectedReport === 'basicCenter';

      if (isCenterReport) {
        this.params!.paging = currentPaging.centersReportPaging;
        this._centerService.setCentersFromReport(this.params!, (paging) => {
          currentPaging.centersReportPaging.total = paging.total;
          if (updateRegions) {
            this.setRegionFromCache();
          }
          this.centerTableParams = this.getCentersTableParams();
        });
      } else {
        this.params!.paging = currentPaging.hcpsReportPaging;
        this._HCPsService.setHCPsFromReport(this.params!, (paging) => {
          currentPaging.hcpsReportPaging.total = paging.total;
          if (updateRegions) {
            this.setRegionFromCache();
          }
          this.hcpTableParams = this.getHCPsTableParams();
        });
      }
    }
  }

  private _setTags(): void {
    const project: TProject | undefined = this._categoriesService.getProject(
      this.reportParams.projectId
    );
    this.projectCustomMetrics =
      project?.centerTagsDefinitions.find(
        (it) => it.connEctedFieldId === 'custom_metrics'
      )?.tags ?? [];

    this.availableTags =
      project?.hcpTagsDefinitions.find(
        (it) => it.connEctedFieldId === `engagementTags`
      )?.tags ?? [];
  }

  private _handleHCPEditClick(row: THCPTableRow): void {
    const projectReviewParams: THCPParams = {
      projectId: this.reportParams.projectId,
      categoryId:
        this._categoriesService.getCategoryFromProjectId(
          this.reportParams.projectId
        )?.categoryId ?? ``,
      __type: TComponentType.hcp,
      hcpId: row.id,
      parent: TComponentParent.report,
    };
    this._cacheService.setSelectedReport({
      reportComponentParams: this.reportComponentParams,
      reportFilters: this.reportFilters,
      selectedRegions: this.selectedRegions.value,
      tags: this.tags.value,
      type: this.selectedReport,
    });
    this._router.navigate([`/${ROUTES_PATHS.hcp}`, projectReviewParams], {
      relativeTo: this._route,
    });
  }

  private _handleCenterEditClick(row: THCPTableRow): void {
    const projectReviewParams: TCenterParams = {
      projectId: this.reportParams.projectId,
      categoryId:
        this._categoriesService.getCategoryFromProjectId(
          this.reportParams.projectId
        )?.categoryId ?? ``,
      __type: TComponentType.hcp,
      centerId: row.id,
      parent: TComponentParent.report,
    };
    this._cacheService.setSelectedReport({
      reportComponentParams: this.reportComponentParams,
      reportFilters: this.reportFilters,
      selectedRegions: this.selectedRegions.value,
      tags: this.tags.value,
      type: this.selectedReport,
    });
    this._router.navigate([`/${ROUTES_PATHS.center}`, projectReviewParams], {
      relativeTo: this._route,
    });
  }
}
