import { TSalesRegion } from './TProject';

export interface TUser {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  countries: string[];
  projects: string[];
  projectsRegions: ProjectRegions[];
  categories: string[];
  level: UserLevel;
  status: UserStatus;
}

export enum UserStatus {
  Active,
  Inactive
}

export enum UserLevel {
  Admin,
  User
}

export interface ProjectRegions {
  projectId: string;
  regions: TSalesRegion[];
}
