import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TSalesRegion } from '@shared/interfaces/TProject';
import { PDFService } from 'src/app/services/PDFService';
import { TBaseSettingsTabParams } from '../project-settings.component';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss'],
})
export class RegionsComponent implements OnInit {
  constructor(
    private _pdfService: PDFService,
    public dialogRef: MatDialogRef<RegionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseSettingsTabParams
  ) {
    this.params = data;
  }

  public newRegionData1: TSalesRegion = {
    id: ``,
    description: ``,
    name: ``,
    salesData: ``,
  };
  public newRegionData2: TSalesRegion = {
    id: ``,
    description: ``,
    name: ``,
    salesData: ``,
  };
  public pdfSrc: Uint8Array = new Uint8Array([]);
  public params: TBaseSettingsTabParams;

  private _isPdfViewerVisible: boolean = false;

  public getRegions1(): TSalesRegion[] {
    return this.params.selectedProject?.regions1 ?? [];
  }

  public getRegions2(): TSalesRegion[] {
    return this.params.selectedProject?.regions2 ?? [];
  }

  public add1() {
    const newRegion = structuredClone(this.newRegionData1);
    // random 4 digit value
    const val = Math.floor(1000 + Math.random() * 9000);
    newRegion.id = `1-${val.toString()}-${newRegion.name
      .toLowerCase()
      .replace(/\s/g, `-`)}`;
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedProject.regions1 === undefined
    ) {
      this.params.selectedProject.regions1 = [newRegion];
    } else {
      this.params.selectedProject?.regions1.push(newRegion);
    }
  }

  public add2() {
    const newRegion = structuredClone(this.newRegionData2);
    // random 4 digit value
    const val = Math.floor(1000 + Math.random() * 9000);
    newRegion.id = `2-${val.toString()}-${newRegion.name
      .toLowerCase()
      .replace(/\s/g, `-`)}`;
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedProject.regions2 === undefined
    ) {
      this.params.selectedProject.regions2 = [newRegion];
    } else {
      this.params.selectedProject?.regions2.push(newRegion);
    }
  }

  private _isNameDuplicated(
    regions: TSalesRegion[] | undefined,
    name: string
  ): boolean {
    return (regions ?? []).map((r) => r.name).includes(name);
  }

  public addAvailable1(): boolean {
    return (
      this.newRegionData1.name !== `` &&
      (this.newRegionData1.description !== ``) !==
        this._isNameDuplicated(
          this.params.selectedProject?.regions1,
          this.newRegionData1.name
        )
    );
  }

  public addAvailable2(): boolean {
    return (
      this.newRegionData2.name !== `` &&
      (this.newRegionData2.description !== ``) !==
        this._isNameDuplicated(
          this.params.selectedProject?.regions2,
          this.newRegionData2.name
        )
    );
  }

  public remove1(region: TSalesRegion): void {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedProject.regions1 !== undefined
    ) {
      const regionIndex: number =
        this.params.selectedProject.regions1.findIndex(
          (r) => r.name === region.name
        );
      this.params.selectedProject.regions1.splice(regionIndex, 1);
    }
  }

  public remove2(region: TSalesRegion): void {
    if (
      this.params.selectedProject !== undefined &&
      this.params.selectedProject.regions2 !== undefined
    ) {
      const regionIndex: number =
        this.params.selectedProject.regions2.findIndex(
          (r) => r.name === region.name
        );
      this.params.selectedProject.regions2.splice(regionIndex, 1);
    }
  }

  public loadPDF(filename: string): void {
    this._pdfService.loadSalesData(filename, (res) => {
      this.pdfSrc = res;
      this._isPdfViewerVisible = true;
    });
  }

  public checkIfPDFViewerVisible(): string {
    return this._isPdfViewerVisible ? `` : `hidden-by-size`;
  }

  ngOnInit(): void {}
}
